import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CustomMenu = _resolveComponent("CustomMenu")!

  return (_openBlock(), _createBlock(_component_CustomMenu, {
    title: _ctx.t('Add channel'),
    options: _ctx.supportedChannels,
    onOnSelect: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-add-channel', $event)))
  }, {
    trigger: _withCtx(({ open }) => [
      _createVNode(_component_a_button, {
        size: "small",
        onClick: open,
        class: "m-auto"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["title", "options"]))
}