
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import EmptyCommunicationChannels from "./EmptyCommunicationChannels.vue";
import CommunicationChannelsList from "./CommunicationChannelsList.vue";
import FormGroupEmail from "@/web/views/CommunicationChannels/CommunicationChannelsForms/FormGroupEmail.vue";
import FormMobileChannel from "@/web/views/CommunicationChannels/CommunicationChannelsForms/FormMobileChannel.vue";
import FormGroupDomain from "@/web/views/CommunicationChannels/CommunicationChannelsForms/FormGroupDomain.vue";
import FormGroupSocials from "@/web/views/CommunicationChannels/CommunicationChannelsForms/FormGroupSocials.vue";
import { useCommunicationChannelList } from "@/shared/composables/CommunicationChannels/useCommunicationChannelList";
import {
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results,
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel,
} from "@/api/communicationChannels/__generated__/CommunicationChannels";
import { useCommunicationEmailChannel } from "@/shared/composables/CommunicationChannels/useCommunicationEmailChannel";
import { message } from "ant-design-vue";
import { useCommunicationMobileChannel } from "@/shared/composables/CommunicationChannels/useCommunicationMobileChannel";
import { useCommunicationDomainChannel } from "@/shared/composables/CommunicationChannels/useCommunicationDomainChannel";
import { useCommunicationSocialChannel } from "@/shared/composables/CommunicationChannels/useCommunicationSocialChannel";

/**
 * TODO: Update the same types as in the backend, for now we will use "MobileChannel" | "EmailChannel"
 * Note: "MobileChannel" | "EmailChannel" are only types and not an enum
 */
type ChannelTypes =
  CommunicationChannels_communicationChannels_CommunicationChannelResults_results["__typename"];

/**
 * TODO: Update the same types as in the backend, for now we will use
 * CommunicationChannels_communicationChannels_CommunicationChannelResults_results directly
 */
type CommunicationChannelResultPartial =
  Partial<CommunicationChannels_communicationChannels_CommunicationChannelResults_results>;

export type EditFormType = CommunicationChannelResultPartial & {
  oldValue?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results["value"];
  verificationCode?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["verificationCode"];
  verificationFile?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["verificationFile"];
  verificationMethod?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["verificationMethod"];
  domainVerificationFileUrl?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["domainVerificationFileUrl"];
  callbackUrl?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["callbackUrl"];
  key?: CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel["key"];
};

/**
 * TThis is just a User-Defined Type Guards
 * https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards
 */
const isEditFormTypeDomain = (
  obj: EditFormType
): obj is CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel => {
  return (
    (
      obj as CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel
    ).verificationCode !== undefined
  );
};

/**
 * to be used when resetting the edit form
 * old value is used to if value is changed on edit form
 */
const emptyEditForm: EditFormType = {
  id: "",
  value: "",
  isVerified: null,
  isPrimary: null,
  created: null,
  oldValue: "",
  callbackUrl: "",
  key: "",
};

export enum Modes {
  // eslint-disable-next-line no-unused-vars
  Channel = "channel",
  // eslint-disable-next-line no-unused-vars
  EditChannel = "edit-channel",
  // eslint-disable-next-line no-unused-vars
  VerifyChannel = "verify-channel",
}

export default defineComponent({
  components: {
    PageLayout,
    EmptyCommunicationChannels,
    CommunicationChannelsList,
    FormGroupEmail,
    FormMobileChannel,
    FormGroupDomain,
    FormGroupSocials,
  },
  setup() {
    const { t } = useI18n();
    const mode = ref(Modes.Channel);
    const { channelList, loading, handleRefetchCommunicationChannel } =
      useCommunicationChannelList();

    const addingNewChannel = ref<ChannelTypes>();
    const editFormState = reactive<EditFormType>({});

    const { handleDeleteEmailChannel } =
      useCommunicationEmailChannel(editFormState);
    const { handleDeleteMobileChannel } =
      useCommunicationMobileChannel(editFormState);
    const { handleDeleteDomainChannel } =
      useCommunicationDomainChannel(editFormState);
    const { handleDeleteSocialChannel } =
      useCommunicationSocialChannel(editFormState);

    const handleOnAddRequest = (channelKey: ChannelTypes) => {
      /**
       * Set editFormState to empty object
       * This is to make sure that the form is empty when we add a new channel
       */
      Object.assign(editFormState, emptyEditForm);

      console.log("handleOnAddRequest", channelKey);
      mode.value = Modes.Channel;
      addingNewChannel.value = channelKey;
    };

    const handleAddNewChannel = async () => {
      await handleRefetchCommunicationChannel();
    };

    const handleEditChannel = (
      formState: CommunicationChannels_communicationChannels_CommunicationChannelResults_results
    ) => {
      console.log("handleEditChannel", formState);

      /**
       * Update the editFormState with the new form state
       * But only some of the properties
       */
      Object.assign(editFormState, {
        id: formState.id,
        value: formState.value,
        isVerified: formState.isVerified,
        isPrimary: formState.isPrimary,
        callbackUrl: formState?.["callbackUrl"],
        key: formState?.["key"],
      });

      // Set mode to edit channel, for checking
      mode.value = Modes.EditChannel;

      addingNewChannel.value = formState.__typename;
    };

    const handleDeleteChannel = async (
      channel: CommunicationChannels_communicationChannels_CommunicationChannelResults_results
    ) => {
      try {
        if (channel?.__typename === "EmailChannel") {
          /**
           * Email Channel delete handler
           */
          await handleDeleteEmailChannel({ emailChannelId: channel.id });
          message.info(t("Email Successfully Deleted."));
        } else if (channel?.__typename === "MobileChannel") {
          /**
           * Mobile Channel delete handler
           */
          await handleDeleteMobileChannel({ mobileChannelId: channel.id });
          message.info(t("Mobile Successfully Deleted."));
        } else if (channel?.__typename === "DomainChannel") {
          /**
           * Domain Channel delete handler
           */
          await handleDeleteDomainChannel({ domainChannelId: channel.id });
          message.info(t("Domain Successfully Deleted."));
        } else if (channel?.__typename === "SocialChannel") {
          /**
           * Social Channel delete handler
           */
          await handleDeleteSocialChannel({ socialChannelId: channel.id });
          message.info(t("Channel Successfully Deleted."));
        } else {
          console.warn("Unhandled channel?.__typename", channel);
        }
      } catch (error) {
        console.log(error);
      } finally {
        await handleRefetchCommunicationChannel();
      }

      addingNewChannel.value = undefined;
    };

    // Verify button will be shown on unverified channels
    const handleVerificationChannel = (
      formState: CommunicationChannels_communicationChannels_CommunicationChannelResults_results
    ) => {
      console.log("handleVerificationChannel", formState);

      /**
       * Update the editFormState with the new form state
       * But only some of the properties
       */
      Object.assign(editFormState, {
        id: formState.id,
        value: formState.value,
        isVerified: formState.isVerified,
        isPrimary: formState.isPrimary,
      });

      if (isEditFormTypeDomain(formState)) {
        editFormState.verificationCode = formState.verificationCode;
        editFormState.verificationFile = formState.verificationFile;
        editFormState.verificationMethod = formState.verificationMethod;
        editFormState.domainVerificationFileUrl =
          formState.domainVerificationFileUrl;
      }

      // Set mode to verify channel
      mode.value = Modes.VerifyChannel;

      addingNewChannel.value = formState.__typename;
    };

    const handleModeChange = (newMode: Modes) => {
      mode.value = newMode;
    };

    /**
     * Use this to update channel list verification status
     */
    const handleVerificationChanged = async () => {
      await handleRefetchCommunicationChannel();
    };

    const handleBack = () => {
      addingNewChannel.value = undefined;
    };

    return {
      t,
      config,
      addingNewChannel,
      channelList,
      loading,
      editFormState,
      handleOnAddRequest,
      handleBack,
      handleAddNewChannel,
      handleEditChannel,
      handleDeleteChannel,
      handleVerificationChanged,
      handleVerificationChannel,
      mode,
      handleModeChange,
      Modes,
    };
  },
});
