import gql from "graphql-tag";

export const createMobileChannelGql = gql`
  mutation CreateMobileChannel($input: CreateMobileChannelInput!) {
    createMobileChannel(input: $input) {
      ... on MobileChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
