
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";
import PlatformIcon from "../Icons/PlatformIcon.vue";

export default defineComponent({
  components: { ConfirmModal, PlatformIcon },
  props: {
    channel: {
      type: Object as PropType<CommunicationChannels_communicationChannels_CommunicationChannelResults_results>,
      required: true,
    },
    showVerificationStatus: {
      type: Boolean,
      default: true,
    },
    showEdit: {
      type: Boolean,
      default: true,
    },
    channelUnverified: {
      type: Boolean,
      default: false,
    },
    removeButton: Boolean,
  },
  emits: ["on-edit", "on-verify", "on-remove"],
  setup() {
    const { t } = useI18n();

    return { t, ConfirmModalTypes };
  },
});
