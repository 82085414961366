import gql from "graphql-tag";

export const channelApplicationsGql = gql`
  query ChannelApplications($input: ChannelApplicationsInput!) {
    channelApplications(input: $input) {
      ... on ApplicationResults {
        results {
          id
          name
        }
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
