import gql from "graphql-tag";

export const deleteEmailChannelGql = gql`
  mutation DeleteEmailChannel($input: DeleteEmailChannelInput!) {
    deleteEmailChannel(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
