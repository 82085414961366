
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";
import { i18nTranslate } from "@/plugins/i18n";
import { useChannelApplications } from "@/shared/composables/CommunicationChannels/useChannelApplications";

export default defineComponent({
  components: { ConfirmModal },
  props: {
    channelId: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: i18nTranslate("Channel removing"),
    },
    isPrimary: Boolean,
    loading: Boolean,
    disabled: Boolean,
    buttonText: {
      type: String,
      default: i18nTranslate("Delete"),
    },
  },
  emits: ["ok"],
  setup(props) {
    const { t } = useI18n();

    const {
      channelApplicationsList,
      channelApplicationsLoading,
      isChannelUsed,
    } = useChannelApplications(props.channelId);

    const bodyText = computed(() => {
      if (props.isPrimary) {
        return t("This channel is used at the Profile as main contact.");
      }

      return isChannelUsed.value
        ? t(
            "This channel is being used as a communication channel in the following application(s): {appList}. By deleting this communication channel, the applications related to it must be configured afterwards to set new communication channels.",
            {
              appList: channelApplicationsList.value
                ?.map((i) => i?.name)
                .join(", "),
            }
          )
        : t("Are you sure you want to remove this channel?");
    });

    return {
      t,
      bodyText,
      ConfirmModalTypes,
      isChannelUsed,
      channelApplicationsList,
      channelApplicationsLoading,
    };
  },
});
