
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCommunicationEmailChannel } from "@/shared/composables/CommunicationChannels/useCommunicationEmailChannel";
import CustomAlert from "@/shared/components/CustomAlert.vue";
import CommonVerifyForm from "./CommonVerifyForm.vue";
import CommonCountdownResend from "@/web/views/CommunicationChannels/CommunicationChannelsForms/CommonCountdownResend.vue";
import attempt from "lodash/attempt";

export default defineComponent({
  components: {
    CustomAlert,
    CommonVerifyForm,
    CommonCountdownResend,
  },
  emits: ["on-change-email"],
  setup() {
    const { t } = useI18n();
    const verifyFormRef = ref();

    const {
      emailState,
      verifyEmailChannel,
      verifyEmailChannelLoading,
      resendEmailVerificationCodeTimeAllowed,
      resendEmailVerificationCodeTimeAllowedFinish,
      handleResendEmailVerificationCode,
      resendEmailVerificationCodeLoading,
    } = useCommunicationEmailChannel({});

    const isVerificationError = ref(false);

    const localFormState = reactive({
      verificationCode: "",
    });

    const handleResendVerificationCode = async () => {
      isVerificationError.value = false; // reset when submitting
      handleResendEmailVerificationCode(emailState.id);
    };

    const handleVerify = async (verificationCode) => {
      isVerificationError.value = false; // reset when submitting

      try {
        await verifyEmailChannel({
          emailChannelId: emailState.id,
          verificationCode,
        });
      } catch (error) {
        console.log(error);
        isVerificationError.value = true;
        attempt(verifyFormRef.value?.clearInput);
      }
    };

    return {
      t,
      verifyFormRef,
      isVerificationError,
      emailState,
      localFormState,
      handleVerify,
      handleResendVerificationCode,
      resendEmailVerificationCodeTimeAllowed,
      resendEmailVerificationCodeTimeAllowedFinish,
      resendEmailVerificationCodeLoading,
      verifyEmailChannelLoading,
    };
  },
});
