import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!

  return (_openBlock(), _createBlock(_component_a_alert, {
    showIcon: _ctx.showIcon,
    type: _ctx.type
  }, _createSlots({ _: 2 }, [
    (_ctx.showIcon)
      ? {
          name: "icon",
          fn: _withCtx(() => [
            (_ctx.type === 'success')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.SuccessIconSrc
                }, null, 8, _hoisted_1))
              : (_ctx.type === 'error')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 1,
                    src: _ctx.ErrorIconSrc
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
          ])
        }
      : undefined
  ]), 1032, ["showIcon", "type"]))
}