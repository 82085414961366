
import { defineComponent } from "vue";
import CommonCardLayout from "../Layouts/CommonCardLayout.vue";
import BackIcon from "@/assets/icons/back.svg";

export default defineComponent({
  emits: ["on-back"],
  props: {
    title: {
      type: String,
      default: "",
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    backButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      BackIcon,
    };
  },
  methods: {},
  components: { CommonCardLayout },
});
