import gql from "graphql-tag";

export const createSocialChannelGql = gql`
  mutation CreateSocialChannel($input: CreateSocialChannelInput!) {
    createSocialChannel(input: $input) {
      ... on SocialChannel {
        id
        value
        isVerified
        isPrimary
        isPublic
        verified
        provider
        created
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
