import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-mobile-verify" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_InputMobile = _resolveComponent("InputMobile")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormMobileChannelVerify = _resolveComponent("FormMobileChannelVerify")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!
  const _component_SingleFormItemLayout = _resolveComponent("SingleFormItemLayout")!

  return (_openBlock(), _createBlock(_component_SingleFormItemLayout, {
    title: _ctx.finalTitle,
    "show-back-button": true,
    class: "form-group-mobile",
    onOnBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-back')))
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (!_ctx.mobileVerificationSent || _ctx.mobileState.isVerified)
            ? (_openBlock(), _createBlock(_component_FormGroup, {
                key: 0,
                model: _ctx.localFormState,
                loading: _ctx.createMobileCommunicationChannelLoading,
                class: "form-group-mobile__form",
                onFinish: _ctx.handleSubmit
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_InputMobile, {
                          value: _ctx.localFormState.mobile,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState.mobile) = $event)),
                          label: _ctx.t('Mobile'),
                          name: "mobile",
                          placeholder: "+x xxx xxxx xx xx",
                          disabled: 
                  _ctx.createMobileCommunicationChannelLoading ||
                  _ctx.updateMobileChannelLoading
                ,
                          readonly: _ctx.mobileState.isVerified,
                          rules: [_ctx.makeRequiredRule(_ctx.t('Mobile is required'))]
                        }, _createSlots({ _: 2 }, [
                          (_ctx.mobileState.isVerified)
                            ? {
                                name: "suffix",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_alert, {
                                    banner: "",
                                    class: "py-0 px-2",
                                    message: _ctx.t('Verified'),
                                    type: "success",
                                    "show-icon": false
                                  }, null, 8, ["message"])
                                ])
                              }
                            : undefined
                        ]), 1032, ["value", "label", "disabled", "readonly", "rules"])
                      ]),
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.mobileVerificationSent && !_ctx.mobileState.isVerified)
                            ? (_openBlock(), _createBlock(_component_SubmitButton, {
                                key: 0,
                                type: "default",
                                class: "form-group-mobile__form-submit-button btn-inline-submit ml-2 mt-7",
                                loading: 
                  _ctx.createMobileCommunicationChannelLoading ||
                  (_ctx.updateMobileChannelLoading && _ctx.isMobileValueUpdated)
                ,
                                disabled: _ctx.updateMobileChannelLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Send code")), 1)
                                ]),
                                _: 1
                              }, 8, ["loading", "disabled"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    (!_ctx.mobileState.isVerified)
                      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                          key: 0,
                          class: "small lighter"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("We will send SMS to this phone number to verify it")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["model", "loading", "onFinish"]))
            : (_ctx.mobileVerificationSent)
              ? (_openBlock(), _createBlock(_component_FormMobileChannelVerify, {
                  key: 1,
                  onOnChangeMobile: _ctx.handleMobileChannelChange
                }, null, 8, ["onOnChangeMobile"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_FormGroup, {
        model: _ctx.localFormState,
        loading: _ctx.updateMobileChannelLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            value: _ctx.localFormState.isPrimary,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFormState.isPrimary) = $event)),
            label: "Main",
            name: "isPrimary",
            class: "m-0",
            disabled: 
          _ctx.updateMobileChannelLoading ||
          _ctx.requireMainChannel ||
          _ctx.resendMobileVerificationCodeLoading
        ,
            extra: 
          _ctx.requireMainChannel
            ? _ctx.t(
                'You can’t turn this channel off. Please, choose another channel.'
              )
            : ''
        ,
            onSwitchChange: _ctx.handleUpdateMobile
          }, null, 8, ["value", "disabled", "extra", "onSwitchChange"])
        ]),
        _: 1
      }, 8, ["model", "loading"]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.mobileState.isVerified)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_a_button, {
                  class: "mt-6",
                  disabled: _ctx.updateMobileChannelLoading,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-back')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Back to channel list")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_ConfirmModal, {
                  title: _ctx.t('Channel removing'),
                  body: 
            _ctx.localFormState.isPrimary
              ? _ctx.t('This channel is used at the Profile as main contact.')
              : _ctx.t('Are you sure you want to remove this channel?')
          ,
                  type: _ctx.ConfirmModalTypes.danger,
                  onOk: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-delete', _ctx.mobileState)))
                }, {
                  trigger: _withCtx(({ open }) => [
                    _createVNode(_component_a_button, {
                      class: "mt-6",
                      danger: "",
                      onClick: open
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t("Delete")), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 1
                }, 8, ["title", "body", "type"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}