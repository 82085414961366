
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTelegramAuthProvider } from "@/shared/composables/Social/useTelegramAuthProvider";

const scriptAttributes = ["size", "telegram-login", "request-access"];

export default defineComponent({
  setup() {
    const telegramRef = ref();
    const { t } = useI18n();
    const { options } = useTelegramAuthProvider();

    onMounted(() => {
      /**
       * This implementation is a workaround for telegram because vue-authenticate-2 is not supported and has different implementation
       */
      const script = document.createElement("script");
      script.async = true;
      script.src = "https://telegram.org/js/telegram-widget.js";

      /**
       * Attach telegram script tags
       */
      scriptAttributes.forEach((attribute) => {
        // set custom data-<tag>
        script.setAttribute(`data-${attribute}`, options[attribute]);
      });

      if (telegramRef.value) {
        telegramRef.value.appendChild(script);

        try {
          /**
           * This will check (every 250ms | 0.25s) if iframe is loaded
           * To be able to hide the widget login button in favor of custom button
           */
          const pollingId = setInterval(() => {
            const iframeId = `telegram-login-${options["telegram-login"]}`;
            const tdIframe = document.getElementById(iframeId);
            if (tdIframe) {
              tdIframe.style.display = "none";
              clearInterval(pollingId); // remove polling as soon as widget is hidden
            }
          }, 250);

          // add time for polling if not yet loaded within 10 seconds
          setTimeout(() => clearInterval(pollingId), 10000);
        } catch (error) {
          console.log(
            "Something went wrong when trying to hide Telegram login",
            error
          );
        }
      }
    });

    return { t, telegramRef };
  },
  methods: {},
  components: {},
});
