import gql from "graphql-tag";

export const deleteSocialChannelGql = gql`
  mutation DeleteSocialChannel($input: DeleteSocialChannelInput!) {
    deleteSocialChannel(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
