
import { defineComponent, watch, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import {
  defaultCleaveOptions,
  useCleaveInstance,
} from "@/shared/composables/useCleaveInstance";
import toString from "lodash/toString";
import InputText from "@/shared/components/Forms/InputText.vue";
import { numberFormats } from "@/plugins/i18n";

// https://github.com/nosir/cleave.js/blob/master/doc/phone-lib-addon.md#how-to-include-phone-lib-addon
// import phone lib add on for all countries
require("cleave.js/dist/addons/cleave-phone.i18n.js");

export default defineComponent({
  components: { InputText },
  props: {
    ...defaultCleaveOptions,
    value: {
      type: [Number, String],
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const { t } = useI18n({
      numberFormats,
    });
    const mobileInputRef = ref();

    const { innerValue, makeCleaveInstance, doUpdateValue } = useCleaveInstance(
      props.disableCleave,
      props.defaultValue,
      {
        phone: true,
      },
      undefined,
      mobileInputRef
    );

    watch(innerValue, (value) => {
      emit("update:value", toString(value).replace(/\s/g, ""));
    });

    /** NOTE: This is to update the 'innerValue' properly when
     * prop 'value' changes */
    watch(
      () => props.value,
      (value) => {
        /** TODO: Remove redundant update on value
         * since it updates twice */
        const newValue = toString(value);
        doUpdateValue(newValue);
      }
    );

    watch(
      () => props.value,
      (value) => {
        /** TODO: Remove redudant update on value
         * since it updates twice */
        // uses explicit comparison since loadash isEmpty returns false positive value
        if (value === null || value === "" || value === undefined) {
          return;
        }
        const newValue = toString(value);
        doUpdateValue(newValue);
      }
    );

    onMounted(() => {
      setTimeout(() => {
        makeCleaveInstance();
      }, 200);
    });

    return {
      t,
      innerValue,
      setInputRef: (ref) => (mobileInputRef.value = ref),
    };
  },
});
