import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChannelItem = _resolveComponent("ChannelItem")!

  return (_openBlock(), _createBlock(_component_ChannelItem, {
    class: "channel-social-item",
    channel: _ctx.parsedChannel,
    "show-edit": _ctx.showEdit,
    "remove-button": "",
    "show-verification-status": _ctx.showVerificationStatus,
    onOnRemove: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-remove', _ctx.channel)))
  }, null, 8, ["channel", "show-edit", "show-verification-status"]))
}