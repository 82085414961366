import gql from "graphql-tag";

export const generateTwitterOauthTokenGql = gql`
  mutation GenerateTwitterOauthToken {
    generateTwitterOauthToken {
      ... on GenerateTwitterOauthTokenResults {
        oauthToken
        oauthTokenSecret
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
