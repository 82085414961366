import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group-mobile__verify" }
const _hoisted_2 = { class: "font-bold" }
const _hoisted_3 = { class: "-mt-2 mb-2" }
const _hoisted_4 = {
  key: 0,
  class: "my-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_CommonVerifyForm = _resolveComponent("CommonVerifyForm")!
  const _component_CustomAlert = _resolveComponent("CustomAlert")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_CommonCountdownResend = _resolveComponent("CommonCountdownResend")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_i18n_t, {
      keypath: "We have sent SMS with 4 digit code to {mobile} to verify it.",
      tag: "div",
      class: "form-group-mobile__verify-sent-text ant-typography-paragraph m-0 mb-5"
    }, {
      mobile: _withCtx(() => [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.mobileState.value), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CommonVerifyForm, {
        ref: "verifyFormRef",
        loading: _ctx.verifyMobileChannelLoading,
        disabled: _ctx.resendMobileVerificationCodeLoading,
        "is-verified": !!_ctx.mobileState.isVerified,
        onOnVerify: _ctx.handleVerify
      }, null, 8, ["loading", "disabled", "is-verified", "onOnVerify"])
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.mobileState.isVerified || _ctx.isVerificationError)
          ? (_openBlock(), _createBlock(_component_CustomAlert, {
              key: 0,
              class: "mb-6 mt-4",
              message: 
          _ctx.mobileState.isVerified
            ? _ctx.t('Your mobile has been verified!')
            : _ctx.t('Wrong verification code')
        ,
              type: _ctx.mobileState.isVerified ? 'success' : 'error',
              "show-icon": ""
            }, null, 8, ["message", "type"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (!_ctx.mobileState.isVerified)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_a_typography_paragraph, { class: "small m-0" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Have not received it?")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_i18n_t, {
            keypath: "{sendAgain} or {change}",
            tag: "div"
          }, {
            sendAgain: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "link",
                size: "small",
                class: "small-text p-0 form-group-mobile__verify-resend-code-button-link",
                loading: _ctx.resendMobileVerificationCodeLoading,
                disabled: 
              _ctx.verifyMobileChannelLoading ||
              !!_ctx.resendMobileVerificationCodeTimeAllowed
            ,
                onClick: _ctx.handleResendVerificationCode
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Send code again")) + " ", 1),
                  (_ctx.resendMobileVerificationCodeTimeAllowed)
                    ? (_openBlock(), _createBlock(_component_CommonCountdownResend, {
                        key: 0,
                        value: _ctx.resendMobileVerificationCodeTimeAllowed,
                        onFinish: _ctx.resendMobileVerificationCodeTimeAllowedFinish
                      }, null, 8, ["value", "onFinish"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["loading", "disabled", "onClick"])
            ]),
            change: _withCtx(() => [
              _createVNode(_component_a_button, {
                type: "link",
                size: "small",
                class: "small-text p-0",
                disabled: 
              _ctx.verifyMobileChannelLoading || _ctx.resendMobileVerificationCodeLoading
            ,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-change-mobile')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("change mobile")), 1)
                ]),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}