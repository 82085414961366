
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  makeRequiredRule,
  makeUrlRule,
  makeURLDomainRule,
} from "@/shared/utils/validators/commonValidators";
import SingleFormItemLayout from "@/shared/components/Layouts/SingleFormItemLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
// import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import {
  EditFormType,
  Modes,
} from "@/web/views/CommunicationChannels/CommunicationChannels.vue";
import { useCommunicationDomainChannel } from "@/shared/composables/CommunicationChannels/useCommunicationDomainChannel";
import {
  addInternetProtocol,
  removeInternetProtocol,
} from "@/shared/utils/internet";
import DomainVerifyForm from "@/web/views/CommunicationChannels/CommunicationChannelsForms/FormGroupDomainComponents/DomainVerifyForm.vue";
import { VerificationMethod } from "__generated__/globalTypes";
import { message } from "ant-design-vue";
import { copyText } from "vue3-clipboard";
import ModalConfirmDeleteChannel from "@/web/views/CommunicationChannels/ModalConfirmDeleteChannel.vue";
import { config } from "@/shared/utils/config";

export type FormGroupEmailModeTypes = "channel" | "profile";
export default defineComponent({
  components: {
    SingleFormItemLayout,
    InputText,
    FormGroup,
    // InputSwitch,
    DomainVerifyForm,
    ModalConfirmDeleteChannel,
  },
  props: {
    mode: {
      type: String as PropType<Modes>,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    requireMainChannel: {
      type: Boolean,
      default: false,
    },
    initialFormState: {
      type: Object as PropType<EditFormType>,
    },
  },
  emits: ["on-back", "on-add", "on-delete"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const submitted = ref(false);
    const {
      domainState,
      createDomainCommunicationChannel,
      createDomainCommunicationChannelLoading,
      handleUpdateDomainChannel,
      updateDomainChannelLoading,
      deleteDomainChannelLoading,
      verifyDomainChannel,
      verifyDomainChannelLoading,
    } = useCommunicationDomainChannel(props.initialFormState);

    const isVerificationError = ref(false);

    // this value is the current and initial value, it doesn't mean it is an old/previous value
    const oldCallbackUrl = ref(
      removeInternetProtocol(props.initialFormState?.callbackUrl ?? "")
    );

    const formState = reactive({
      domain: props.initialFormState?.value || "",
      isPrimary: props.initialFormState?.isPrimary || props.requireMainChannel, // use this as initial value
      callbackUrl: removeInternetProtocol(
        props.initialFormState?.callbackUrl ?? ""
      ),
      key: props.initialFormState?.key,
    });

    const handleUpdateDomain = async () => {
      try {
        const updateDomainInput: {
          isPrimary: boolean;
          value: string;
          callbackUrl?: string;
        } = {
          isPrimary: formState.isPrimary,
          value: formState.domain,
        };

        const shouldIncludeCBUrl =
          !!formState.callbackUrl &&
          oldCallbackUrl.value !== formState.callbackUrl;

        // only add callback url if there's a value or the value changed
        if (shouldIncludeCBUrl) {
          updateDomainInput.callbackUrl = addInternetProtocol(
            formState.callbackUrl
          );
        }
        await handleUpdateDomainChannel(updateDomainInput);
        message.info(t("Channel updated"));

        if (shouldIncludeCBUrl) {
          // update old value to detect next domain value changed
          oldCallbackUrl.value = formState.callbackUrl;
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleSubmit = async () => {
      try {
        /**
         * Check if domain is changed
         */
        if (domainState.oldValue && formState.domain === domainState.oldValue) {
          domainState.value = formState.domain;
          domainState.oldValue = "";
          return;
        }
        /**
         * If ID already exists, just update the domain
         * It means that domain were only changed
         */
        if (domainState.id) {
          await handleUpdateDomain();
        } else {
          // remove internet protocol automatically
          await createDomainCommunicationChannel({
            value: removeInternetProtocol(formState.domain),
            isPrimary: formState.isPrimary,
          });
          emit("on-add");
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleDomainVerify = async (
      verificationMethod: VerificationMethod
    ) => {
      isVerificationError.value = false; //reset when submitting

      try {
        await verifyDomainChannel({
          domainChannelId: domainState.id as string,
          verificationMethod,
        });
      } catch (error) {
        console.log("FormGroupDomain.vue:handleDomainVerify", error);
        isVerificationError.value = true;
      }
    };

    const copy = (textToCopy) => {
      copyText(textToCopy, undefined, (error) => {
        if (error) {
          console.log(error);
          message.info(t(`Error copying to clipboard.`));
        } else {
          message.info(t("Key has been copied to Clipboard."));
        }
      });
    };

    return {
      t,
      config,
      copy,
      Modes,
      submitted,
      formState,
      domainState,
      updateDomainChannelLoading,
      deleteDomainChannelLoading,
      handleSubmit,
      handleUpdateDomain,
      createDomainCommunicationChannelLoading,
      handleDomainVerify,
      verifyDomainChannelLoading,
      finalTitle: computed(() => {
        if (props.title) return props.title;
        if (props.mode === Modes.EditChannel) return t("Edit channel");
        if (props.mode === Modes.VerifyChannel) return t("Verify channel");

        return t("Add channel");
      }),
      isVerificationError,
      oldCallbackUrl,
    };
  },
  methods: {
    makeRequiredRule,
    makeUrlRule,
    makeURLDomainRule,
  },
});
