import gql from "graphql-tag";

export const verifyDomainChannelGql = gql`
  mutation VerifyDomainChannel($input: VerifyDomainChannelInput!) {
    verifyDomainChannel(input: $input) {
      ... on DomainChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationLastAttempt
        verificationAttemptCount
        updated
        verificationCode
        verificationFile
        domainVerificationFileUrl
        verificationMethod
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
