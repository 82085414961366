import gql from "graphql-tag";

export const updateEmailChannelGql = gql`
  mutation UpdateEmailChannel($input: UpdateEmailChannelInput!) {
    updateEmailChannel(input: $input) {
      ... on EmailChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
