/* eslint-disable @typescript-eslint/no-explicit-any */
import { ref, watch, nextTick, onMounted, getCurrentInstance, Ref } from "vue";
import Cleave, { CleaveOptions } from "cleave.js";

export const defaultCleaveOptions = {
  defaultValue: {
    type: String,
  },
  disableCleave: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
  },
};

/**
 * Use this to setup cleave instance
 *
 * @param disableCleave option to disable cleave
 * @param defaultValue default value for input
 * @param cleaveOptions cleave options, check https://github.com/nosir/cleave.js/blob/master/doc/options.md
 * @param makeDoOnInput optional function to run when input is updated
 *
 * Usage:
 * 	1. Initialize component
 *
 *      useCleaveInstance(...)
 *
 *  2. Add to template
 *
 * 			<input v-model:value="innerValue" />
 *
 * 3. (Optional) Add watchers to emit event, etc.
 *
 */
export function useCleaveInstance(
  disableCleave: boolean,
  defaultValue: string | undefined,
  cleaveOptions: CleaveOptions,
  // eslint-disable-next-line no-unused-vars
  makeDoOnInput?: (cleave: Cleave) => (event: Event) => void,
  domElement?: Ref<HTMLElement | any>
): {
  cleave: Cleave | null;
  innerValue: Ref<string | null>;
  makeCleaveInstance: () => void;
  // eslint-disable-next-line no-unused-vars
  doUpdateValue: (value: string) => void;
} {
  const internalInstance = getCurrentInstance();

  const innerValue = ref<string | null>(null);
  let cleave = null as Cleave | null;

  const doUpdateValue = (value: string) => {
    innerValue.value = value;
  };

  const onValueChanged = ({ target }) => {
    nextTick(() => {
      doUpdateValue(target.value);
    });
  };

  watch(
    () => internalInstance?.props.value,
    (newValue) => {
      if (!innerValue.value) {
        doUpdateValue(newValue as string);
      } else if (internalInstance?.props.value == null) {
        innerValue.value = null;
      }
    },
    { immediate: true }
  );

  const makeCleaveInstance = () => {
    if (!disableCleave) {
      if (cleave && cleave.destroy) {
        cleave.destroy();
      }

      let inputEl;
      if (domElement) {
        inputEl = domElement.value?.$el;
      } else if (internalInstance && internalInstance.vnode.el) {
        inputEl = internalInstance.vnode.el.parentElement.querySelector(
          "input"
        ) as HTMLElement;
      }

      if (inputEl) {
        cleave = new Cleave(inputEl, {
          ...cleaveOptions,
          onValueChanged: onValueChanged.bind(internalInstance),
        });

        if (makeDoOnInput) {
          inputEl.oninput = makeDoOnInput(cleave);
        }

        if (defaultValue && cleave) {
          cleave.setRawValue(defaultValue);
        }
      }
    }
  };

  onMounted(() => {
    makeCleaveInstance();
  });

  return {
    cleave,
    innerValue,
    makeCleaveInstance,
    doUpdateValue,
  };
}
