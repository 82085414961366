import gql from "graphql-tag";

export const deleteMobileChannelGql = gql`
  mutation DeleteMobileChannel($input: DeleteMobileChannelInput!) {
    deleteMobileChannel(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
