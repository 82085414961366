import { config } from "@/shared/utils/config";
import { reactive } from "vue";

/**
 * Telegram returned authorization data
 * See https://core.telegram.org/widgets/login#receiving-authorization-data
 */
export type TelegramAuthData = {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: number;
  hash: string;
};

// this is outside of composable to be globally available
const options = reactive({
  bot_id: config.authTelegramBotId,
  "telegram-login": config.authTelegramBotUser,
  size: "small",
  "request-access": "read",
  "auth-url": config.authTelegramDataAuthUrl,
  origin: config.authTelegramAuthOrigin,
});

/**
 * For available window methods see https://telegram.org/js/telegram-widget.js
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _window: any = window;

export const useTelegramAuthProvider = () => {
  const telegramAuthenticate = () => {
    return new Promise<TelegramAuthData>((resolve, reject) => {
      const auth = _window.Telegram.Login.auth;
      try {
        if (auth) {
          auth(options, (authData) => {
            if (!authData) {
              return reject(new Error("no data or user canceled"));
            }
            resolve(authData);
          });
        } else {
          reject(new Error("window.Telegram.Login.auth is missing"));
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  return {
    telegramAuthenticate,
    options,
  };
};
