
import { computed, defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { message } from "ant-design-vue";
import SingleFormItemLayout from "@/shared/components/Layouts/SingleFormItemLayout.vue";
import SocialConnector from "@/shared/components/Social/SocialConnector.vue";
import { supportedSocialPlatforms } from "@/plugins/vueAuthenticatePlugin";
import { useSocialAuthProvider } from "@/shared/composables/Social/useSocialAuthProvider";
import { useCommunicationSocialChannel } from "@/shared/composables/CommunicationChannels/useCommunicationSocialChannel";
import { i18nTranslate } from "@/plugins/i18n";
import { SocialChannelProvider } from "../../../../../__generated__/globalTypes";
import { config } from "@/shared/utils/config";

export default defineComponent({
  components: {
    SingleFormItemLayout,
    SocialConnector,
  },
  props: {
    title: {
      type: String,
      default: i18nTranslate("Add channel"),
    },
  },
  emits: ["on-back", "on-add"],
  setup(props, { emit }) {
    const { t } = useI18n();
    /**
     * {
     *  <platform-name>: <platform-user>,
     * }
     *
     * TODO: Integrate this, not integrated because UI were removed during integration
     */
    const connectedSocialPlatforms = reactive({});
    const {
      createSocialCommunicationChannel,
      createSocialCommunicationChannelLoadingProvider,
    } = useCommunicationSocialChannel({});

    const { authenticate, authenticatingProvider } = useSocialAuthProvider();

    const handleAuthenticate = async (provider) => {
      try {
        /**
         * Handle getting the authorization code from the provider.
         */
        const authDetails = await authenticate(provider);
        console.log("handleAuthenticate:authDetails", authDetails);

        /**
         * Handle creating the social communication channel
         */
        const response = await createSocialCommunicationChannel({
          provider: authDetails.provider,
          authorizationCode: authDetails.code || authDetails.oauthToken,
          authorizationSecret: authDetails.oauthTokenSecret,
          authorizationVerifier: authDetails.oauth_verifier,
          ...(provider === SocialChannelProvider.TELEGRAM
            ? {
                extraDetail: authDetails.extraDetail,
              }
            : {}),
          ...(provider === SocialChannelProvider.TWITTER
            ? {
                redirectUrl: config.authTwitterRedirectUri,
              }
            : {}),
        });

        /**
         * Check response if successfully created
         * created is a date string
         */
        if (response?.created) {
          // triggers parent component to reload the channel list
          emit("on-add");

          // triggers parent component to go back
          emit("on-back");

          // show success message on channel list page
          message.info(t("Account successfully linked!"));
        }
      } catch (error) {
        console.log(
          "Something went wrong when trying to create a social channel",
          error
        );
      }
    };

    return {
      t,
      handleAuthenticate,
      authenticatingProvider,
      createSocialCommunicationChannelLoadingProvider,
      supportedSocialPlatformsList: computed(() =>
        supportedSocialPlatforms.map((platform) => ({
          name: platform.name,
          label: platform.label,
          value: connectedSocialPlatforms?.[platform.name], // replace value with the connected social platform
          info: platform.info,
        }))
      ),
    };
  },
});
