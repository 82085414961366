import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "channel-item flex items-center" }
const _hoisted_2 = { class: "channel-item__detail-container flex flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PlatformIcon, {
      class: "channel-item__icon",
      name: _ctx.channel.__typename
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_space, {
        size: 8,
        class: "flex-1"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_row, {
            gutter: 8,
            class: "channel-item__detail flex items-center"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, { class: "channel-item__detail-col" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_typography_paragraph, { class: "m-0" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.channel.value), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, { class: "channel-item__detail-col" }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, { size: 8 }, {
                    default: _withCtx(() => [
                      (_ctx.channel.isPrimary)
                        ? (_openBlock(), _createBlock(_component_a_alert, {
                            key: 0,
                            message: _ctx.t('Main'),
                            type: "success",
                            banner: "",
                            "show-icon": false
                          }, null, 8, ["message"]))
                        : _createCommentVNode("", true),
                      (_ctx.showVerificationStatus)
                        ? (_openBlock(), _createBlock(_component_a_alert, {
                            key: 1,
                            message: _ctx.channel.isVerified ? _ctx.t('Verified') : _ctx.t('Unverified'),
                            type: _ctx.channel.isVerified ? 'success' : 'error',
                            banner: "",
                            "show-icon": false
                          }, null, 8, ["message", "type"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_space, {
        size: 8,
        class: "channel-item__detail-container-action"
      }, {
        default: _withCtx(() => [
          (_ctx.showEdit)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                size: "small",
                class: "px-2",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-edit', _ctx.channel)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Edit")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.channelUnverified)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 1,
                size: "small",
                class: "px-2",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-verify', _ctx.channel)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("Verify")), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.channelUnverified || _ctx.removeButton)
            ? (_openBlock(), _createBlock(_component_ConfirmModal, {
                key: 2,
                title: _ctx.t('Channel removing'),
                body: 
            _ctx.channel.isPrimary
              ? _ctx.t('This channel is used at the Profile as main contact.')
              : _ctx.t('Are you sure you want to remove this channel?')
          ,
                type: _ctx.ConfirmModalTypes.danger,
                onOk: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-remove', _ctx.channel)))
              }, {
                trigger: _withCtx(({ open }) => [
                  _createVNode(_component_a_button, {
                    size: "small",
                    class: "px-2",
                    danger: "",
                    onClick: open
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Delete")), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["title", "body", "type"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ]))
}