import gql from "graphql-tag";

export const resendEmailChannelVerificationCodeGql = gql`
  mutation ResendEmailChannelVerificationCode(
    $input: ResendEmailChannelInput!
  ) {
    resendEmailChannelVerificationCode(input: $input) {
      ... on EmailChannel {
        id
        value
        created
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
