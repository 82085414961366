
import { computed, defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { getSocialIcon } from "@/shared/utils/social";
import ChannelItem from "@/shared/components/Channels/ChannelItem.vue";
// import ConfirmModal, {
//   ConfirmModalTypes,
// } from "@/shared/components/Modals/ConfirmModal.vue";
// import FormGroup from "@/shared/components/Forms/FormGroup.vue";
// import FormGroupItemMainChannel from "@/shared/components/FormGroups/FormGroupItemMainChannel.vue";
import { message } from "ant-design-vue";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results_SocialChannel } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import clone from "lodash/clone";

export default defineComponent({
  components: {
    ChannelItem,
    // ConfirmModal,
    // FormGroupItemMainChannel,
    // FormGroup,
  },
  props: {
    channel: {
      type: Object as PropType<CommunicationChannels_communicationChannels_CommunicationChannelResults_results_SocialChannel>,
      required: true,
    },
    showVerificationStatus: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-edit", "on-remove"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const formState = reactive({
      mainChannel: props.channel?.isPrimary,
    });

    const handleSwitchMainChannel = (value) => {
      console.log("handleSwitchMainChannel:value", value);
      if (value) {
        message.info(
          t("Main channel switched to {platform}: {account}", {
            platform: props.channel.provider,
            account: props.channel.value,
          })
        );
      } else {
        message.info(t("Main channel updated"));
      }

      emit("on-edit", {
        ...props.channel,
        main: value,
      });
    };

    return {
      t,
      // ConfirmModalTypes,
      formState,
      handleSwitchMainChannel,
      parsedChannel: computed(() => {
        /**
         * create a copy of channel object
         * any type is used so that setting __typename with the value of provider is possible
         * do not mutate original object because it will be used for updating
         */
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let copyChannel: any = clone(props.channel);

        /**
         * check if __typename is SocialChannel, update __typeChannel to use provider
         * this parsedChannel is only use for display because platform icon uses __typename to get icon
         */
        if (copyChannel.__typename === "SocialChannel") {
          copyChannel.__typename = copyChannel.provider;
        }
        return copyChannel;
      }),
    };
  },
  methods: {
    getSocialIcon,
  },
});
