import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "domain-verify-form" }
const _hoisted_2 = { class: "pt-4" }
const _hoisted_3 = { class: "inline" }
const _hoisted_4 = { class: "mt-2" }
const _hoisted_5 = { class: "mt-2 domain-verify-form__dashed" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "pt-4" }
const _hoisted_9 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_CustomAlert = _resolveComponent("CustomAlert")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_i18n_t, {
      keypath: "Instructions to verify {domain}",
      tag: "h3",
      class: "ant-typography mb-6"
    }, {
      domain: _withCtx(() => [
        _createElementVNode("strong", null, _toDisplayString(_ctx.domainState?.value), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_tabs, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_tab_pane, {
          key: "1",
          tab: _ctx.t('Via TXT record')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("ol", null, [
                _createElementVNode("li", null, _toDisplayString(_ctx.t("Copy the verification code.")), 1),
                _createVNode(_component_a_typography_text, { copyable: "" }, {
                  default: _withCtx(() => [
                    _createElementVNode("code", _hoisted_3, _toDisplayString(_ctx.domainState?.verificationCode), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("li", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.t("Go to your domain's DNS records and add a new record.")) + " ", 1),
                  _createElementVNode("ul", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dnsRecordInstruction, (ins, index) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: 'dns-record-Inst-' + index,
                        innerHTML: ins
                      }, null, 8, _hoisted_6))
                    }), 128))
                  ])
                ]),
                _createVNode(_component_a_typography_text, null, {
                  default: _withCtx(() => [
                    _createElementVNode("blockquote", {
                      class: "my-2",
                      innerHTML: 
                  _ctx.t(
                    `<strong>Note</strong>: If you see a warning message about changing your DNS settings, disregard it. Adding the TXT record won't damage your website or DNS settings.`
                  )
                
                    }, null, 8, _hoisted_7)
                  ]),
                  _: 1
                }),
                _createElementVNode("li", null, _toDisplayString(_ctx.t("Click the button below to finish verification.")), 1)
              ]),
              (_ctx.isVerificationError || _ctx.domainState.isVerified)
                ? (_openBlock(), _createBlock(_component_CustomAlert, {
                    key: 0,
                    class: "mt-2 form-group-domain__alert",
                    message: 
              _ctx.domainState.isVerified
                ? _ctx.t('Your domain has been verified.')
                : _ctx.t(
                    'We didn’t find your DNS TXT record. Please check your settings and try again. Note that it might take a while for for your registrar to publish the verification code'
                  )
            ,
                    type: _ctx.domainState.isVerified ? 'success' : 'error',
                    "show-icon": ""
                  }, null, 8, ["message", "type"]))
                : _createCommentVNode("", true),
              (!_ctx.domainState.isVerified)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    class: "my-2",
                    type: "primary",
                    loading: _ctx.verifyDomainChannelLoading,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleVerify(_ctx.VerificationMethod.TXT)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Verify domain")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_typography_text, null, {
                default: _withCtx(() => [
                  _createElementVNode("blockquote", null, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.t("Important!")), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.t(
                  "It might take additional time for your registrar to publish the verification code. If you receive an error message, wait an hour before you try again. In extreme cases, it can take up to 48 hours for your registrar to publish your verification code."
                )), 1)
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["tab"]),
        _createVNode(_component_a_tab_pane, {
          key: "2",
          tab: _ctx.t('Via file')
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("ol", null, [
                _createElementVNode("li", null, _toDisplayString(_ctx.t("Download the following .txt file.")), 1),
                _createVNode(_component_a_button, {
                  size: "small",
                  class: "small-text",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (
                _ctx.downloadData(
                  _ctx.domainState?.verificationFile,
                  _ctx.removeInternetProtocol(_ctx.verificationFileName ?? '')
                )
              ))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.removeInternetProtocol(_ctx.verificationFileName ?? "")), 1)
                  ]),
                  _: 1
                }),
                _createElementVNode("li", {
                  class: "whitespace-pre-line mt-2",
                  innerHTML: 
                _ctx.t(
                  'Have the content of the file available at <strong>{domainVerificationFileUrl}</strong>. The content should be available in the body of the response.',
                  {
                    domainVerificationFileUrl:
                      _ctx.domainState?.domainVerificationFileUrl,
                  }
                )
              
                }, null, 8, _hoisted_9),
                _createElementVNode("li", null, _toDisplayString(_ctx.t("Click the button below to verify your domain.")), 1)
              ]),
              (_ctx.isVerificationError || _ctx.domainState.isVerified)
                ? (_openBlock(), _createBlock(_component_CustomAlert, {
                    key: 0,
                    class: "mt-2 form-group-domain__alert",
                    message: 
              _ctx.domainState.isVerified
                ? _ctx.t('Your domain has been verified.')
                : _ctx.t(
                    'We didn’t find text file on the domain. Make sure it’s in the root directory.'
                  )
            ,
                    type: _ctx.domainState.isVerified ? 'success' : 'error',
                    "show-icon": ""
                  }, null, 8, ["message", "type"]))
                : _createCommentVNode("", true),
              (!_ctx.domainState.isVerified)
                ? (_openBlock(), _createBlock(_component_a_button, {
                    key: 1,
                    type: "primary",
                    loading: _ctx.verifyDomainChannelLoading,
                    class: "my-2",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleVerify(_ctx.VerificationMethod.FILE)))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Verify domain")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["tab"])
      ]),
      _: 1
    })
  ]))
}