import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormGroupEmail = _resolveComponent("FormGroupEmail")!
  const _component_FormMobileChannel = _resolveComponent("FormMobileChannel")!
  const _component_FormGroupDomain = _resolveComponent("FormGroupDomain")!
  const _component_FormGroupSocials = _resolveComponent("FormGroupSocials")!
  const _component_CommunicationChannelsList = _resolveComponent("CommunicationChannelsList")!
  const _component_EmptyCommunicationChannels = _resolveComponent("EmptyCommunicationChannels")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    title: _ctx.t('Communication channels')
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.addingNewChannel === 'EmailChannel')
            ? (_openBlock(), _createBlock(_component_FormGroupEmail, {
                key: 0,
                mode: _ctx.mode,
                "initial-form-state": _ctx.editFormState,
                onOnAdd: _ctx.handleAddNewChannel,
                onOnBack: _ctx.handleBack,
                onOnDelete: _ctx.handleDeleteChannel,
                onVerificationChanged: _ctx.handleVerificationChanged
              }, null, 8, ["mode", "initial-form-state", "onOnAdd", "onOnBack", "onOnDelete", "onVerificationChanged"]))
            : (_ctx.addingNewChannel === 'MobileChannel')
              ? (_openBlock(), _createBlock(_component_FormMobileChannel, {
                  key: 1,
                  mode: _ctx.mode,
                  "initial-form-state": _ctx.editFormState,
                  onOnBack: _ctx.handleBack,
                  onOnDelete: _ctx.handleDeleteChannel,
                  onVerificationChanged: _ctx.handleVerificationChanged
                }, null, 8, ["mode", "initial-form-state", "onOnBack", "onOnDelete", "onVerificationChanged"]))
              : (_ctx.addingNewChannel === 'DomainChannel')
                ? (_openBlock(), _createBlock(_component_FormGroupDomain, {
                    key: 2,
                    mode: _ctx.mode,
                    "initial-form-state": _ctx.editFormState,
                    onOnAdd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleModeChange(_ctx.Modes.VerifyChannel))),
                    onOnBack: _ctx.handleBack,
                    onOnDelete: _ctx.handleDeleteChannel,
                    onVerificationChanged: _ctx.handleVerificationChanged
                  }, null, 8, ["mode", "initial-form-state", "onOnBack", "onOnDelete", "onVerificationChanged"]))
                : (_ctx.addingNewChannel === 'socials')
                  ? (_openBlock(), _createBlock(_component_FormGroupSocials, {
                      key: 3,
                      mode: "channel",
                      onOnAdd: _ctx.handleAddNewChannel,
                      onOnBack: _ctx.handleBack,
                      onVerificationChanged: _ctx.handleVerificationChanged
                    }, null, 8, ["onOnAdd", "onOnBack", "onVerificationChanged"]))
                  : (_ctx.channelList.length > 0)
                    ? (_openBlock(), _createBlock(_component_CommunicationChannelsList, {
                        key: 4,
                        "channel-list": _ctx.channelList,
                        onOnAddChannel: _ctx.handleOnAddRequest,
                        onOnDeleteChannel: _ctx.handleDeleteChannel,
                        onOnEditChannel: _ctx.handleEditChannel,
                        onOnVerifyChannel: _ctx.handleVerificationChannel
                      }, null, 8, ["channel-list", "onOnAddChannel", "onOnDeleteChannel", "onOnEditChannel", "onOnVerifyChannel"]))
                    : (_ctx.channelList.length < 1)
                      ? (_openBlock(), _createBlock(_component_EmptyCommunicationChannels, {
                          key: 5,
                          loading: _ctx.loading,
                          onOnAddChannel: _ctx.handleOnAddRequest
                        }, null, 8, ["loading", "onOnAddChannel"]))
                      : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}