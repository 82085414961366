import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ConfirmModal = _resolveComponent("ConfirmModal")!

  return (_openBlock(), _createBlock(_component_ConfirmModal, {
    title: _ctx.title,
    body: _ctx.bodyText,
    type: _ctx.ConfirmModalTypes.danger,
    onOk: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('ok')))
  }, {
    trigger: _withCtx(({ open }) => [
      _createVNode(_component_a_button, {
        class: "mt-6",
        danger: "",
        onClick: open
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.buttonText), 1)
        ]),
        _: 2
      }, 1032, ["onClick"])
    ]),
    _: 1
  }, 8, ["title", "body", "type"]))
}