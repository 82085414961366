
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  makeEmailRule,
  makeRequiredRule,
} from "@/shared/utils/validators/commonValidators";
import SingleFormItemLayout from "@/shared/components/Layouts/SingleFormItemLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { useCommunicationEmailChannel } from "@/shared/composables/CommunicationChannels/useCommunicationEmailChannel";
import {
  EditFormType,
  Modes,
} from "@/web/views/CommunicationChannels/CommunicationChannels.vue";
import FormEmailChannelVerify from "./FormEmailChannelVerify.vue";
import ModalConfirmDeleteChannel from "@/web/views/CommunicationChannels/ModalConfirmDeleteChannel.vue";

export default defineComponent({
  components: {
    SingleFormItemLayout,
    FormGroup,
    InputSwitch,
    InputText,
    SubmitButton,
    FormEmailChannelVerify,
    ModalConfirmDeleteChannel,
  },
  props: {
    mode: {
      type: String as PropType<Modes>,
      required: true,
    },
    requireMainChannel: {
      type: Boolean,
      default: false,
    },
    initialFormState: {
      type: Object as PropType<EditFormType>,
    },
  },
  emits: ["on-back", "on-add", "on-delete"],
  setup(props, { emit }) {
    const isEmailChanged = ref(false);
    const { t } = useI18n();
    const {
      emailState,
      resendEmailVerificationCodeLoading,
      createEmailCommunicationChannel,
      createEmailCommunicationChannelLoading,
      verifyEmailChannelLoading,
      handleUpdateEmailChannel,
      updateEmailChannelLoading,
      deleteEmailChannelLoading,
    } = useCommunicationEmailChannel(props.initialFormState);

    const localFormState = reactive({
      email: props.initialFormState?.value || "",
      isPrimary: props.initialFormState?.isPrimary || props.requireMainChannel, // use this as initial value
    });

    const handleUpdateEmail = async () => {
      try {
        await handleUpdateEmailChannel({
          isPrimary: localFormState.isPrimary,
          value: localFormState.email,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const isEmailValueUpdated = computed(
      () =>
        !!emailState.oldValue && emailState.oldValue !== localFormState.email
    );

    const handleSubmit = async () => {
      try {
        /**
         * Check if email number is changed
         */
        if (localFormState.email === emailState.oldValue) {
          emailState.value = localFormState.email;
          emailState.oldValue = "";
          return;
        }

        /**
         * If ID already exists, just update the number
         * It means that email number were only changed
         */
        if (emailState.id) {
          await handleUpdateEmail();
        } else {
          await createEmailCommunicationChannel({
            email: localFormState.email,
            isPrimary: localFormState.isPrimary,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        // emit "on-add" event so it can trigger refetch
        emit("on-add");
      }
    };

    const handleEmailChannelChange = () => {
      /**
       * Save current value to oldValue property
       * This will be used for checking if email value is really change before update email number
       */
      emailState.oldValue = emailState.value;
      emailState.value = "";
      isEmailChanged.value = true;
    };

    return {
      isEmailChanged,
      t,
      Modes,
      emailState,
      localFormState,
      isEmailValueUpdated,
      emailVerificationSent: computed(
        () =>
          (!!emailState.verificationCodeLastSent ||
            props.mode === Modes.VerifyChannel) &&
          emailState.value
      ),
      handleSubmit,
      handleUpdateEmail,
      createEmailCommunicationChannelLoading,
      verifyEmailChannelLoading,
      updateEmailChannelLoading,
      handleEmailChannelChange,
      deleteEmailChannelLoading,
      resendEmailVerificationCodeLoading,
      finalTitle: computed(() => {
        if (
          props.mode === Modes.EditChannel ||
          (emailState.oldValue && !emailState.value)
        ) {
          /**
           * if email old value is not empty and new value is empty
           * it means that the email is being changed, see handleEmailChannelChange
           */
          return t("Edit channel");
        } else if (props.mode === Modes.VerifyChannel) {
          return t("Verify channel");
        } else {
          return t("Add channel");
        }
      }),
    };
  },
  methods: { makeRequiredRule, makeEmailRule },
});
