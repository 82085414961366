
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import PlatformIcon from "@/shared/components/Icons/PlatformIcon.vue";
import SocialConnectorTelegram from "@/shared/components/Social/SocialConnectorTelegramScript.vue";
import { getSocialIcon } from "@/shared/utils/social";
import { supportedSocialPlatforms } from "@/plugins/vueAuthenticatePlugin";
import { Provider } from "../../../../__generated__/globalTypes";

export default defineComponent({
  emits: ["on-oauth-request"],
  props: {
    platform: {
      type: Object as PropType<typeof supportedSocialPlatforms[0]>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      Provider,
    };
  },
  methods: {
    getSocialIcon,
  },
  components: { SocialConnectorTelegram, PlatformIcon },
});
