import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SocialConnector = _resolveComponent("SocialConnector")!
  const _component_SingleFormItemLayout = _resolveComponent("SingleFormItemLayout")!

  return (_openBlock(), _createBlock(_component_SingleFormItemLayout, {
    title: _ctx.title,
    "show-back-button": "",
    onOnBack: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back')))
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.supportedSocialPlatformsList, (platform) => {
        return (_openBlock(), _createBlock(_component_SocialConnector, {
          key: platform.name,
          platform: platform,
          loading: 
        _ctx.authenticatingProvider === platform.name ||
        _ctx.createSocialCommunicationChannelLoadingProvider === platform.name
      ,
          disabled: 
        !!_ctx.authenticatingProvider ||
        !!_ctx.createSocialCommunicationChannelLoadingProvider
      ,
          onOnOauthRequest: _ctx.handleAuthenticate
        }, null, 8, ["platform", "loading", "disabled", "onOnOauthRequest"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["title"]))
}