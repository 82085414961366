import { getCurrentInstance, ref } from "vue";
import {
  Provider,
  SocialChannelProvider,
} from "../../../../__generated__/globalTypes";
import { useTelegramAuthProvider } from "./useTelegramAuthProvider";
import { useCommunicationSocialChannel } from "@/shared/composables/CommunicationChannels/useCommunicationSocialChannel";
import { compactObject, sortObjectByKeys } from "@/shared/utils/object";
import omit from "lodash/omit";

export function useSocialAuthProvider() {
  /**
   * Vue 3 provides getCurrentInstance() inside the setup() hook. That instance allows access to global properties
   * (installed from plugins) via appContext.config.globalProperties
   *
   * https://stackoverflow.com/questions/66957578/how-to-get-the-this-instance-in-vue-3
   */
  const instance = getCurrentInstance();
  const auth = instance?.appContext?.config?.globalProperties.$auth;
  const { telegramAuthenticate } = useTelegramAuthProvider();

  const { handleGenerateTwitterOauthToken } = useCommunicationSocialChannel({});

  /**
   * this saves the current provider being authenticated
   */
  const authenticatingProvider = ref("");

  const authenticate = async (provider) => {
    if (!auth) {
      console.error("VueAuthenticate instance not found!");
    }

    try {
      // set the current provider being authenticated
      authenticatingProvider.value = provider;
      const userData: Record<string, unknown> = {};
      let userOptions: Record<string, unknown> = {};

      /**
       * partial data for multi-steps authentication
       * e.g. twitter has several steps and some are on backend side and some are on twitter side
       */
      let partialData: Record<string, unknown> = {};

      if (provider === SocialChannelProvider.TWITTER) {
        /**
         * Set generated twitter oauth step 1 to user Options and partial data
         * create social channel endpoint will need this data again
         */
        const generateTwitterOauth = await handleGenerateTwitterOauthToken();
        partialData = compactObject(generateTwitterOauth);

        userOptions = {
          oauthToken: generateTwitterOauth?.oauthToken,
          oauthTokenSecret: generateTwitterOauth?.oauthTokenSecret,
        };
      }

      if (provider === Provider.TELEGRAM) {
        // TODO: Add handling for telegram authentication
        const tgResponse = await telegramAuthenticate();
        partialData["code"] = tgResponse.hash;
        partialData["extraDetail"] = JSON.stringify({
          ...sortObjectByKeys(omit(tgResponse, ["hash"])),
          auth_date: String(tgResponse.auth_date), // convert to string
        });
        console.log("tgResponse", tgResponse);

        // telegramAuthenticate handles popup authentication
        return { provider, ...partialData };
      }
      /**
       * try to link the user to the provider
       * this will not try to authenticated the user with the authorization code
       * but will just get the authorization code and save it to the backend
       */
      const response = await auth.link(provider, userData, userOptions);

      return { provider, ...partialData, ...response };
    } catch (error) {
      console.log("Failed to authenticate", error);
    } finally {
      authenticatingProvider.value = "";
    }
  };

  return {
    authenticate,
    authenticatingProvider,
  };
}
