import gql from "graphql-tag";

export const resendMobileChannelVerificationCodeGql = gql`
  mutation ResendMobileChannelVerificationCode(
    $input: ResendMobileChannelInput!
  ) {
    resendMobileChannelVerificationCode(input: $input) {
      ... on MobileChannel {
        id
        value
        created
        isVerified
        isPrimary
        isPublic
        verified
        verificationCodeLastSent
        verificationLastAttempt
        verificationAttemptCount
        updated
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
