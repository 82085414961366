import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, renderList as _renderList, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, _mergeProps(_ctx.$attrs, { class: "single-form-item-layout" }), _createSlots({
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.showBackButton)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              type: "text",
              class: "p-0",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-back'))),
              size: "small",
              disabled: _ctx.backButtonDisabled
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: _ctx.BackIcon,
                  alt: "back",
                  class: "mr-3",
                  height: "24"
                }, null, 8, _hoisted_2)
              ]),
              _: 1
            }, 8, ["disabled"]))
          : _createCommentVNode("", true),
        _createVNode(_component_a_typography_title, {
          level: 3,
          class: "m-0"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
        ])
      }
    })
  ]), 1040))
}