import { computed, reactive, ref, watch } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createEmailChannelGql } from "@/api/communicationChannels/createEmailChannel";
import {
  CreateEmailChannel,
  CreateEmailChannelVariables,
  CreateEmailChannel_createEmailChannel_EmailChannel,
} from "@/api/communicationChannels/__generated__/CreateEmailChannel";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { verifyEmailChannelGql } from "@/api/communicationChannels/verifyEmailChannel";
import {
  VerifyEmailChannel,
  VerifyEmailChannelVariables,
  VerifyEmailChannel_verifyEmailChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/VerifyEmailChannel";
import { updateEmailChannelGql } from "@/api/communicationChannels/updateEmailChannel";
import {
  UpdateEmailChannel,
  UpdateEmailChannelVariables,
  UpdateEmailChannel_updateEmailChannel_EmailChannel,
} from "@/api/communicationChannels/__generated__/UpdateEmailChannel";
import {
  DeleteEmailChannel,
  DeleteEmailChannelVariables,
  DeleteEmailChannel_deleteEmailChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/DeleteEmailChannel";
import { deleteEmailChannelGql } from "@/api/communicationChannels/deleteEmailChannel";
import merge from "lodash/merge";
import { communicationChannelsGql } from "@/api/communicationChannels/communicationChannelList";
import { resendEmailChannelVerificationCodeGql } from "@/api/communicationChannels/resendEmailChannelVerificationCode";
import {
  ResendEmailChannelVerificationCode,
  ResendEmailChannelVerificationCodeVariables,
  ResendEmailChannelVerificationCode_resendEmailChannelVerificationCode_EmailChannel,
} from "@/api/communicationChannels/__generated__/ResendEmailChannelVerificationCode";
import { getResendVerificationCodeDataMs } from "@/shared/utils/date";

/**
 * __typename is required for the on-delete emit to work
 */
const emptyEmailState = {
  __typename: "EmailChannel",
  id: null,
  value: "",
  isVerified: false,
  isPrimary: false,
  isPublic: false,
  verified: null,
  verificationCodeLastSent: null,
  verificationLastAttempt: null,
};

/**
 * Old Value will be used to check when user try to change email number
 */
interface EmailChannelState
  extends CreateEmailChannel_createEmailChannel_EmailChannel {
  oldValue: CreateEmailChannel_createEmailChannel_EmailChannel["value"];
}

export const useCommunicationEmailChannel = (initialFormState) => {
  /**
   * Merge initial form state with empty email state
   * Initial form state has values if email channel is being edited
   */
  const emailState = reactive<Partial<EmailChannelState>>(
    merge(emptyEmailState, initialFormState)
  );

  /**
   * CREATE EMAIL CHANNEL
   */
  const createEmailChannelMutation = useCustomMutation<
    CreateEmailChannel,
    CreateEmailChannelVariables
  >(createEmailChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const createEmailCommunicationChannel = async ({ email, isPrimary }) => {
    const createEmailChannelResponse = await createEmailChannelMutation.mutate({
      input: {
        email,
        isPrimary,
      },
    });
    const parsedResponse =
      parseGqlResponse<CreateEmailChannel_createEmailChannel_EmailChannel>(
        "EmailChannel",
        createEmailChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "createEmailCommunicationChannel:parsedResponse",
      parsedResponse
    );

    if (!isEmpty(parsedResponse.error?.errors) || !createEmailChannelResponse) {
      throw new Error("Failed to create email channel");
    }

    if (parsedResponse.data) {
      // assign the new email channel to the state if it was created successfully
      Object.assign(emailState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END CREATE EMAIL CHANNEL
   */

  /**
   * VERIFY EMAIL CHANNEL
   */
  const verifyEmailChannelMutation = useCustomMutation<
    VerifyEmailChannel,
    VerifyEmailChannelVariables
  >(verifyEmailChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const verifyEmailChannel = async ({ emailChannelId, verificationCode }) => {
    const verifyEmailChannelResponse = await verifyEmailChannelMutation.mutate({
      input: { emailChannelId, verificationCode },
    });

    const parsedResponse =
      parseGqlResponse<VerifyEmailChannel_verifyEmailChannel_GenericSuccess>(
        "GenericSuccess",
        verifyEmailChannelResponse,
        apiErrorCodes.VERIFICATION_CODE_DOES_NOT_MATCH
      );

    console.log("verifyEmailChannel:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !verifyEmailChannelResponse) {
      throw new Error("Failed to verify email channel");
    }

    /**
     * Manually update the state of the email channel
     * because the response does not contain the updated email channel
     */
    if (parsedResponse.data?.success) {
      emailState.isVerified = true;
    }

    return parsedResponse.data;
  };
  /**
   * END VERIFY EMAIL CHANNEL
   */

  /**
   * UPDATE EMAIL CHANNEL
   */
  const updateEmailChannelMutation = useCustomMutation<
    UpdateEmailChannel,
    UpdateEmailChannelVariables
  >(updateEmailChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleUpdateEmailChannel = async ({ isPrimary, value }) => {
    if (!emailState.id) {
      return;
    }

    const updateEmailChannelResponse = await updateEmailChannelMutation.mutate({
      input: {
        emailChannelId: emailState.id,
        isPrimary,
        value,
      },
    });
    const parsedResponse =
      parseGqlResponse<UpdateEmailChannel_updateEmailChannel_EmailChannel>(
        "EmailChannel",
        updateEmailChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleUpdateEmailChannel:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !updateEmailChannelResponse) {
      throw new Error("Failed to update email channel");
    }

    /**
     * Use updated email state
     */
    if (parsedResponse.data) {
      // assign the new email channel to the state if it was created successfully
      Object.assign(emailState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END UPDATE EMAIL CHANNEL
   */

  /**
   * DELETE EMAIL CHANNEL
   */
  const deleteEmailChannelMutation = useCustomMutation<
    DeleteEmailChannel,
    DeleteEmailChannelVariables
  >(deleteEmailChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleDeleteEmailChannel = async ({ emailChannelId }) => {
    const deleteEmailChannelResponse = await deleteEmailChannelMutation.mutate({
      input: { emailChannelId },
    });
    const parsedResponse =
      parseGqlResponse<DeleteEmailChannel_deleteEmailChannel_GenericSuccess>(
        "GenericSuccess",
        deleteEmailChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleDeleteEmailChannel:parsedResponse", parsedResponse);

    if (!isEmpty(parsedResponse.error?.errors) || !deleteEmailChannelResponse) {
      throw new Error("Failed to delete email channel");
    }

    return parsedResponse.data;
  };
  /**
   * END DELETE EMAIL CHANNEL
   */

  /**
   * RESEND EMAIL VERIFICATION CODE
   */
  const {
    mutate: resendEmailCodeMutate,
    loading: resendEmailVerificationCodeLoading,
  } = useCustomMutation<
    ResendEmailChannelVerificationCode,
    ResendEmailChannelVerificationCodeVariables
  >(resendEmailChannelVerificationCodeGql);
  const handleResendEmailVerificationCode = async (emailChannelId) => {
    const resendEmailCodeResponse = await resendEmailCodeMutate({
      input: {
        emailChannelId,
      },
    });

    const parsedResponse =
      parseGqlResponse<ResendEmailChannelVerificationCode_resendEmailChannelVerificationCode_EmailChannel>(
        "EmailChannel",
        resendEmailCodeResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "handleResendEmailVerificationCode:parsedResponse",
      parsedResponse
    );

    if (!isEmpty(parsedResponse.error?.errors) || !resendEmailCodeResponse) {
      throw new Error("Failed to resend email verification code.");
    }

    /**
     * Use updated email state
     */
    if (parsedResponse.data?.id) {
      // assign the new email channel to the state
      Object.assign(emailState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  const resendEmailVerificationCodeTimeAllowed = ref(
    getResendVerificationCodeDataMs(emailState.verificationCodeLastSent, 0)
  );
  const resendEmailVerificationCodeTimeAllowedFinish = () => {
    resendEmailVerificationCodeTimeAllowed.value = 0;
  };

  watch(emailState, (newEmailState) => {
    /**
     * Check if verificationCodeLastSent exists
     * 60 seconds will be added to this value so verification
     * code sending wont be available during the next 60 seconds
     */
    if (newEmailState.verificationCodeLastSent) {
      resendEmailVerificationCodeTimeAllowed.value =
        getResendVerificationCodeDataMs(newEmailState.verificationCodeLastSent);
    }
  });

  /**
   * END RESEND EMAIL VERIFICATION CODE
   */

  return {
    emailState,
    createEmailCommunicationChannel,
    createEmailCommunicationChannelLoading: computed(
      () => createEmailChannelMutation.loading.value
    ),
    verifyEmailChannel,
    verifyEmailChannelLoading: computed(
      () => verifyEmailChannelMutation.loading.value
    ),
    handleUpdateEmailChannel,
    updateEmailChannelLoading: computed(
      () => updateEmailChannelMutation.loading.value
    ),
    handleDeleteEmailChannel,
    deleteEmailChannelLoading: computed(
      () => deleteEmailChannelMutation.loading.value
    ),
    resendEmailVerificationCodeTimeAllowed,
    handleResendEmailVerificationCode,
    resendEmailVerificationCodeLoading,
    resendEmailVerificationCodeTimeAllowedFinish,
  };
};
