import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputOtp = _resolveComponent("InputOtp")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_FormGroup = _resolveComponent("FormGroup")!

  return (_openBlock(), _createBlock(_component_FormGroup, {
    class: "common-verify-form",
    model: _ctx.localFormState,
    layout: "inline",
    loading: _ctx.loading,
    disabled: _ctx.disabled,
    onFinish: _ctx.handleVerify
  }, {
    default: _withCtx(() => [
      _createVNode(_component_InputOtp, {
        ref: "inputOtpRef",
        value: _ctx.localFormState.verificationCode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState.verificationCode) = $event)),
        name: "verificationCode",
        class: "common-verify-form__otp-input",
        size: 4,
        onOnComplete: _ctx.handleVerify
      }, null, 8, ["value", "onOnComplete"]),
      (!_ctx.isVerified)
        ? (_openBlock(), _createBlock(_component_SubmitButton, {
            key: 0,
            type: "default",
            loading: _ctx.loading,
            disabled: _ctx.disabled,
            class: "btn-inline-submit mt-1"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Verify")), 1)
            ]),
            _: 1
          }, 8, ["loading", "disabled"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["model", "loading", "disabled", "onFinish"]))
}