import { computed, reactive, ref } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createSocialChannelGql } from "@/api/communicationChannels/createSocialChannel";
import {
  CreateSocialChannel,
  CreateSocialChannelVariables,
  CreateSocialChannel_createSocialChannel_SocialChannel,
} from "@/api/communicationChannels/__generated__/CreateSocialChannel";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import {
  DeleteSocialChannel,
  DeleteSocialChannelVariables,
  DeleteSocialChannel_deleteSocialChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/DeleteSocialChannel";
import { deleteSocialChannelGql } from "@/api/communicationChannels/deleteSocialChannel";
import merge from "lodash/merge";
import {
  GenerateTwitterOauthToken,
  GenerateTwitterOauthToken_generateTwitterOauthToken_GenerateTwitterOauthTokenResults,
} from "@/api/communicationChannels/__generated__/GenerateTwitterOauthToken";
import { generateTwitterOauthTokenGql } from "@/api/communicationChannels/generateTwitterOauthToken";
import { compactObject } from "@/shared/utils/object";
import { Provider } from "__generated__/globalTypes";

/**
 * __typename is required for the on-delete emit to work
 */
const emptySocialState = {
  __typename: "SocialChannel",
  id: null,
  value: "",
  isVerified: false,
  isPrimary: false,
  isPublic: false,
  provider: null,
};

export const useCommunicationSocialChannel = (initialFormState) => {
  /**
   * Ref provider that is being created
   */
  const createSocialCommunicationChannelLoadingProvider = ref("");

  /**
   * Merge initial form state with empty social state
   * Initial form state has values if social channel is being edited
   */
  const socialState = reactive<
    Partial<CreateSocialChannel_createSocialChannel_SocialChannel>
  >(merge(emptySocialState, initialFormState));

  /**
   * CREATE SOCIAL CHANNEL
   */
  const createSocialChannelMutation = useCustomMutation<
    CreateSocialChannel,
    CreateSocialChannelVariables
  >(createSocialChannelGql);
  const createSocialCommunicationChannel = async ({
    authorizationCode,
    provider,
    authorizationVerifier,
    authorizationSecret,
    redirectUrl,
    extraDetail,
  }: {
    authorizationCode: string;
    provider: Provider;
    authorizationVerifier: string;
    authorizationSecret: string;
    redirectUrl?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extraDetail?: any;
  }) => {
    // set loading provider, this will be used to know what provider is being created
    createSocialCommunicationChannelLoadingProvider.value = provider;

    const createSocialChannelResponse =
      await createSocialChannelMutation.mutate({
        input: {
          authorizationCode,
          provider,
          ...compactObject({
            authorizationVerifier, // from twitter
            authorizationSecret, // oauthTokenSecret from backend generateTwitterOauthToken mutation
            authorizationCode, // oauthToken from backend generateTwitterOauthToken mutation
            redirectUrl,
            extraDetail,
          }),
        },
      });
    const parsedResponse =
      parseGqlResponse<CreateSocialChannel_createSocialChannel_SocialChannel>(
        "SocialChannel",
        createSocialChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "createSocialCommunicationChannel:parsedResponse",
      parsedResponse
    );

    // clear loading provider value
    createSocialCommunicationChannelLoadingProvider.value = "";

    if (!isEmpty(parsedResponse.error?.errors)) {
      throw new Error("Failed create social channel");
    }

    if (parsedResponse.data) {
      // assign the new social channel to the state if it was created successfully
      Object.assign(socialState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END CREATE SOCIAL CHANNEL
   */

  /**
   * DELETE SOCIAL CHANNEL
   */
  const deleteSocialChannelMutation = useCustomMutation<
    DeleteSocialChannel,
    DeleteSocialChannelVariables
  >(deleteSocialChannelGql);
  const handleDeleteSocialChannel = async ({ socialChannelId }) => {
    const deleteSocialChannelResponse =
      await deleteSocialChannelMutation.mutate({ input: { socialChannelId } });
    const parsedResponse =
      parseGqlResponse<DeleteSocialChannel_deleteSocialChannel_GenericSuccess>(
        "GenericSuccess",
        deleteSocialChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleDeleteSocialChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !deleteSocialChannelResponse
    ) {
      throw new Error("Failed to delete social channel");
    }

    return parsedResponse.data;
  };
  /**
   * END DELETE SOCIAL CHANNEL
   */

  /**
   * GENERATE TWITTER OAUTH TOKEN
   */
  const {
    mutate: generateTwitterOauthTokenMutate,
    loading: generateTwitterOauthTokenLoading,
  } = useCustomMutation<GenerateTwitterOauthToken>(
    generateTwitterOauthTokenGql
  );
  const handleGenerateTwitterOauthToken = async () => {
    const generateTwitterOauthRes = await generateTwitterOauthTokenMutate();
    const parsedResponse =
      parseGqlResponse<GenerateTwitterOauthToken_generateTwitterOauthToken_GenerateTwitterOauthTokenResults>(
        "GenerateTwitterOauthTokenResults",
        generateTwitterOauthRes,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "handleGenerateTwitterOauthToken:parsedResponse",
      parsedResponse
    );

    if (!isEmpty(parsedResponse.error?.errors) || !generateTwitterOauthRes) {
      throw new Error("Failed to generate twitter oauth token");
    }

    return parsedResponse.data;
  };
  /**
   * END GENERATE TWITTER OAUTH TOKEN
   */

  return {
    socialState,
    createSocialCommunicationChannel,
    createSocialCommunicationChannelLoading: computed(
      () => createSocialChannelMutation.loading.value
    ),
    createSocialCommunicationChannelLoadingProvider,
    handleDeleteSocialChannel,
    deleteSocialChannelLoading: computed(
      () => deleteSocialChannelMutation.loading.value
    ),
    handleGenerateTwitterOauthToken,
    generateTwitterOauthTokenLoading,
  };
};
