import { computed } from "vue";
import { channelApplicationsGql } from "@/api/application/channelApplications";
import {
  ChannelApplications,
  ChannelApplicationsVariables,
  ChannelApplications_channelApplications_ApplicationResults,
} from "@/api/application/__generated__/ChannelApplications";
import { useQuerySingleNode } from "@/api/graphqlClient/useQuerySingleNode";
import isEmpty from "lodash/isEmpty";

export const useChannelApplications = (channelId: string) => {
  const { data: channelApplicationsData, loading: channelApplicationsLoading } =
    useQuerySingleNode<
      ChannelApplications_channelApplications_ApplicationResults,
      ChannelApplications,
      ChannelApplicationsVariables
    >(
      channelApplicationsGql,
      "ApplicationResults",
      () => ({ input: { channelId: Number(channelId) } }),
      () => ({ enabled: !!channelId })
    );

  const channelApplicationsList = computed(
    () => channelApplicationsData?.value?.results
  );

  return {
    channelApplicationsList,
    isChannelUsed: computed(() => !isEmpty(channelApplicationsList.value)),
    channelApplicationsData,
    channelApplicationsLoading,
  };
};
