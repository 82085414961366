
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { VerificationMethod } from "../../../../../../__generated__/globalTypes";
import isString from "lodash/isString";
import { CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel } from "@/api/communicationChannels/__generated__/CommunicationChannels";
import { downloadData } from "@/shared/utils/browser";
import CustomAlert from "@/shared/components/CustomAlert.vue";
import { removeInternetProtocol } from "@/shared/utils/internet";

export default defineComponent({
  emits: ["on-verify-request"],
  props: {
    domainState: Object as PropType<
      Partial<CommunicationChannels_communicationChannels_CommunicationChannelResults_results_DomainChannel>
    >,
    verifyDomainChannelLoading: Boolean,
    isVerificationError: Boolean,
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const handleVerify = (verificationMethod: VerificationMethod) => {
      emit("on-verify-request", verificationMethod);
    };

    const dnsRecordInstruction = [
      t("Select <strong>TXT</strong> as the record type."),
      t(
        "In the <strong>Name/Host/Alias</strong> field, enter @ or leave it blank. Your host might require you to enter your domain, which looks like “example.com”, into this field. Your other DNS records might indicate what you should enter."
      ),
      t(
        "In the <strong>Time to Live (TTL)</strong> field, enter <strong>86400</strong> or leave the default."
      ),
      t(
        "In the <strong>Value/Answer/Destination</strong> field, paste the verification record you copied in step 1."
      ),
      t("Save the record."),
    ];

    return {
      t,
      handleVerify,
      dnsRecordInstruction,
      VerificationMethod,
      verificationFileName: computed(() =>
        props.domainState?.domainVerificationFileUrl?.replace(
          `${props.domainState?.value}/`,
          ""
        )
      ),
    };
  },
  methods: { isString, downloadData, removeInternetProtocol },
  components: { CustomAlert },
});
