import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-email-verify" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-1" }
const _hoisted_4 = {
  key: 0,
  class: "flex justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_SubmitButton = _resolveComponent("SubmitButton")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_FormEmailChannelVerify = _resolveComponent("FormEmailChannelVerify")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_ModalConfirmDeleteChannel = _resolveComponent("ModalConfirmDeleteChannel")!
  const _component_SingleFormItemLayout = _resolveComponent("SingleFormItemLayout")!

  return (_openBlock(), _createBlock(_component_SingleFormItemLayout, {
    title: _ctx.finalTitle,
    "show-back-button": true,
    class: "form-group-email",
    onOnBack: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('on-back')))
  }, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (!_ctx.emailVerificationSent || _ctx.emailState.isVerified)
            ? (_openBlock(), _createBlock(_component_FormGroup, {
                key: 0,
                model: _ctx.localFormState,
                loading: _ctx.createEmailCommunicationChannelLoading,
                class: "form-group-email__form",
                onFinish: _ctx.handleSubmit
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_InputText, {
                          value: _ctx.localFormState.email,
                          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localFormState.email) = $event)),
                          label: _ctx.t('Email'),
                          name: "email",
                          placeholder: "john_smeeth@gmail.com",
                          disabled: 
                  _ctx.createEmailCommunicationChannelLoading ||
                  _ctx.updateEmailChannelLoading
                ,
                          readonly: _ctx.emailState.isVerified,
                          rules: [
                  _ctx.makeRequiredRule(_ctx.t('Email is required')),
                  _ctx.makeEmailRule(_ctx.t('Please enter a valid email')),
                ]
                        }, _createSlots({ _: 2 }, [
                          (_ctx.emailState.isVerified)
                            ? {
                                name: "suffix",
                                fn: _withCtx(() => [
                                  _createVNode(_component_a_alert, {
                                    banner: "",
                                    class: "py-0 px-2",
                                    message: _ctx.t('Verified'),
                                    type: "success",
                                    "show-icon": false
                                  }, null, 8, ["message"])
                                ])
                              }
                            : undefined
                        ]), 1032, ["value", "label", "disabled", "readonly", "rules"])
                      ]),
                      _createVNode(_Transition, {
                        name: "fade",
                        mode: "out-in"
                      }, {
                        default: _withCtx(() => [
                          (!_ctx.emailVerificationSent && !_ctx.emailState.isVerified)
                            ? (_openBlock(), _createBlock(_component_SubmitButton, {
                                key: 0,
                                type: "default",
                                class: "form-group-email__form-submit-button btn-inline-submit ml-2 mt-7",
                                loading: 
                  _ctx.createEmailCommunicationChannelLoading ||
                  (_ctx.updateEmailChannelLoading && _ctx.isEmailValueUpdated)
                ,
                                disabled: _ctx.updateEmailChannelLoading
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t("Send code")), 1)
                                ]),
                                _: 1
                              }, 8, ["loading", "disabled"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    (!_ctx.emailState.isVerified)
                      ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                          key: 0,
                          class: "small lighter"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("We will send email to your address to verify it")), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["model", "loading", "onFinish"]))
            : (_ctx.emailVerificationSent)
              ? (_openBlock(), _createBlock(_component_FormEmailChannelVerify, {
                  key: 1,
                  onOnAdd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-add'))),
                  onOnChangeEmail: _ctx.handleEmailChannelChange
                }, null, 8, ["onOnChangeEmail"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_FormGroup, {
        model: _ctx.localFormState,
        loading: _ctx.updateEmailChannelLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_InputSwitch, {
            value: _ctx.localFormState.isPrimary,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localFormState.isPrimary) = $event)),
            label: "Main",
            name: "isPrimary",
            class: "m-0",
            disabled: 
          _ctx.updateEmailChannelLoading ||
          _ctx.requireMainChannel ||
          _ctx.resendEmailVerificationCodeLoading
        ,
            extra: 
          _ctx.requireMainChannel
            ? _ctx.t(
                'You can’t turn this channel off. Please, choose another channel.'
              )
            : ''
        ,
            onSwitchChange: _ctx.handleUpdateEmail
          }, null, 8, ["value", "disabled", "extra", "onSwitchChange"])
        ]),
        _: 1
      }, 8, ["model", "loading"]),
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.emailState.isVerified)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_a_button, {
                  class: "mt-6",
                  disabled: _ctx.updateEmailChannelLoading,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-back')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Back to channel list")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_ModalConfirmDeleteChannel, {
                  "channel-id": _ctx.emailState.id ?? '',
                  "is-primary": _ctx.localFormState.isPrimary,
                  loading: _ctx.deleteEmailChannelLoading,
                  disabled: _ctx.updateEmailChannelLoading,
                  onOk: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('on-delete', _ctx.emailState)))
                }, null, 8, ["channel-id", "is-primary", "loading", "disabled"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title"]))
}