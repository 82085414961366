import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_SocialConnectorTelegram = _resolveComponent("SocialConnectorTelegram")!
  const _component_PlatformIcon = _resolveComponent("PlatformIcon")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    type: "flex",
    class: "social-connector mb-6"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { flex: "5.5rem" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.platform.label), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { flex: "1" }, {
        default: _withCtx(() => [
          (_ctx.platform.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_a_typography_paragraph, { class: "mb-1" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.platform.value), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_button, {
                  size: "small",
                  danger: "",
                  disabled: _ctx.disabled
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Remove")), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_ctx.platform.name === _ctx.Provider.TELEGRAM)
                  ? (_openBlock(), _createBlock(_component_SocialConnectorTelegram, { key: 0 }))
                  : _createCommentVNode("", true),
                _createVNode(_component_a_button, {
                  class: "social-connector__button",
                  size: "small",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-oauth-request', _ctx.platform.name))),
                  loading: _ctx.loading,
                  disabled: _ctx.disabled
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PlatformIcon, {
                      name: _ctx.platform.name,
                      class: "social-connector__button-img"
                    }, null, 8, ["name"]),
                    _createTextVNode(" " + _toDisplayString(_ctx.t("Login with {platform}", { platform: _ctx.platform.label })), 1)
                  ]),
                  _: 1
                }, 8, ["loading", "disabled"])
              ], 64)),
          (_ctx.platform.info)
            ? (_openBlock(), _createBlock(_component_a_typography_paragraph, {
                key: 2,
                class: "small lighter mt-2 mb-0"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.platform.info), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}