
import { defineComponent } from "vue";
import ErrorIconSrc from "@/assets/warning-icon.svg";
import SuccessIconSrc from "@/assets/success-icon.svg";

export default defineComponent({
  props: {
    showIcon: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  setup() {
    return {
      ErrorIconSrc,
      SuccessIconSrc,
    };
  },
  methods: {},
  components: {},
});
