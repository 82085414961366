
import { defineComponent, onMounted } from "vue";

export default defineComponent({
  emits: ["finish"],
  props: {
    value: {
      default: 0,
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      /**
       * The countdown component will not trigger finish if the value is in the pass
       * To fix this, add checking on mount to check if value is in the pass to emit finish
       */
      if (props?.value <= new Date().getTime()) {
        emit("finish");
      }
    });
    return {};
  },
});
