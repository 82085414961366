
import { computed, defineComponent, PropType, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import SingleFormItemLayout from "@/shared/components/Layouts/SingleFormItemLayout.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputSwitch from "@/shared/components/Forms/InputSwitch.vue";
import InputMobile from "@/shared/components/Forms/InputMobile.vue";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import { useCommunicationMobileChannel } from "@/shared/composables/CommunicationChannels/useCommunicationMobileChannel";
import {
  EditFormType,
  Modes,
} from "@/web/views/CommunicationChannels/CommunicationChannels.vue";
import FormMobileChannelVerify from "./FormMobileChannelVerify.vue";
import ConfirmModal, {
  ConfirmModalTypes,
} from "@/shared/components/Modals/ConfirmModal.vue";

export default defineComponent({
  emits: ["on-back", "on-delete"],
  props: {
    mode: {
      type: String as PropType<Modes>,
      required: true,
    },
    requireMainChannel: {
      type: Boolean,
      default: false,
    },
    initialFormState: {
      type: Object as PropType<EditFormType>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const {
      mobileState,
      resendMobileVerificationCodeLoading,
      createMobileCommunicationChannel,
      createMobileCommunicationChannelLoading,
      verifyMobileChannelLoading,
      handleUpdateMobileChannel,
      updateMobileChannelLoading,
      deleteMobileChannelLoading,
    } = useCommunicationMobileChannel(props.initialFormState);

    const localFormState = reactive({
      mobile: props.initialFormState?.value || "",
      isPrimary: props.initialFormState?.isPrimary || props.requireMainChannel, // use this as initial value
    });

    const handleUpdateMobile = async () => {
      try {
        await handleUpdateMobileChannel({
          isPrimary: localFormState.isPrimary,
          value: localFormState.mobile,
        });
      } catch (error) {
        console.log(error);
      }
    };

    const isMobileValueUpdated = computed(
      () =>
        !!mobileState.oldValue && mobileState.oldValue !== localFormState.mobile
    );

    const handleSubmit = async () => {
      try {
        /**
         * Check if mobile number is changed
         */
        if (localFormState.mobile === mobileState.oldValue) {
          mobileState.value = localFormState.mobile;
          mobileState.oldValue = "";
          return;
        }

        /**
         * If ID already exists, just update the number
         * It means that mobile number were only changed
         */
        if (mobileState.id) {
          await handleUpdateMobile();
        } else {
          await createMobileCommunicationChannel({
            mobile: localFormState.mobile,
            isPrimary: localFormState.isPrimary,
          });
        }
      } catch (error) {
        console.log(error);
      }
    };

    const handleMobileChannelChange = () => {
      /**
       * Save current value to oldValue property
       * This will be used for checking if mobile value is really change before update mobile number
       */
      mobileState.oldValue = mobileState.value;
      mobileState.value = "";
    };

    return {
      t,
      Modes,
      ConfirmModalTypes,
      mobileState,
      localFormState,
      isMobileValueUpdated,
      mobileVerificationSent: computed(
        () =>
          (!!mobileState.verificationCodeLastSent ||
            props.mode === Modes.VerifyChannel) &&
          mobileState.value
      ),
      handleSubmit,
      handleUpdateMobile,
      createMobileCommunicationChannelLoading,
      verifyMobileChannelLoading,
      updateMobileChannelLoading,
      handleMobileChannelChange,
      deleteMobileChannelLoading,
      resendMobileVerificationCodeLoading,
      finalTitle: computed(() => {
        if (
          props.mode === Modes.EditChannel ||
          (mobileState.oldValue && !mobileState.value)
        ) {
          /**
           * if mobile old value is not empty and new value is empty
           * it means that the mobile is being changed, see handleMobileChannelChange
           */
          return t("Edit channel");
        } else if (props.mode === Modes.VerifyChannel) {
          return t("Verify channel");
        } else {
          return t("Add channel");
        }
      }),
    };
  },
  methods: { makeRequiredRule },
  components: {
    SingleFormItemLayout,
    FormGroup,
    InputSwitch,
    InputMobile,
    SubmitButton,
    FormMobileChannelVerify,
    ConfirmModal,
  },
});
