import { computed, reactive, ref, watch } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createMobileChannelGql } from "@/api/communicationChannels/createMobileChannel";
import {
  CreateMobileChannel,
  CreateMobileChannelVariables,
  CreateMobileChannel_createMobileChannel_MobileChannel,
} from "@/api/communicationChannels/__generated__/CreateMobileChannel";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { verifyMobileChannelGql } from "@/api/communicationChannels/verifyMobileChannel";
import {
  VerifyMobileChannel,
  VerifyMobileChannelVariables,
  VerifyMobileChannel_verifyMobileChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/VerifyMobileChannel";
import { updateMobileChannelGql } from "@/api/communicationChannels/updateMobileChannel";
import {
  UpdateMobileChannel,
  UpdateMobileChannelVariables,
  UpdateMobileChannel_updateMobileChannel_MobileChannel,
} from "@/api/communicationChannels/__generated__/UpdateMobileChannel";
import {
  DeleteMobileChannel,
  DeleteMobileChannelVariables,
  DeleteMobileChannel_deleteMobileChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/DeleteMobileChannel";
import { deleteMobileChannelGql } from "@/api/communicationChannels/deleteMobileChannel";
import merge from "lodash/merge";
import { communicationChannelsGql } from "@/api/communicationChannels/communicationChannelList";
import {
  ResendMobileChannelVerificationCode,
  ResendMobileChannelVerificationCodeVariables,
  ResendMobileChannelVerificationCode_resendMobileChannelVerificationCode_MobileChannel,
} from "@/api/communicationChannels/__generated__/ResendMobileChannelVerificationCode";
import { resendMobileChannelVerificationCodeGql } from "@/api/communicationChannels/resendMobileChannelVerificationCode";
import { getResendVerificationCodeDataMs } from "@/shared/utils/date";

/**
 * __typename is required for the on-delete emit to work
 */
const emptyMobileState = {
  __typename: "MobileChannel",
  id: null,
  value: "",
  isVerified: false,
  isPrimary: false,
  isPublic: false,
  verificationCodeLastSent: null,
  verificationLastAttempt: null,
};

/**
 * Old Value will be used to check when user try to change mobile number
 */
interface MobileChannelState
  extends CreateMobileChannel_createMobileChannel_MobileChannel {
  oldValue: CreateMobileChannel_createMobileChannel_MobileChannel["value"];
}

export const useCommunicationMobileChannel = (initialFormState) => {
  /**
   * Merge initial form state with empty mobile state
   * Initial form state has values if mobile channel is being edited
   */
  const mobileState = reactive<Partial<MobileChannelState>>(
    merge(emptyMobileState, initialFormState)
  );

  /**
   * CREATE MOBILE CHANNEL
   */
  const createMobileChannelMutation = useCustomMutation<
    CreateMobileChannel,
    CreateMobileChannelVariables
  >(createMobileChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const createMobileCommunicationChannel = async ({ mobile, isPrimary }) => {
    const createMobileChannelResponse =
      await createMobileChannelMutation.mutate({
        input: {
          mobile,
          isPrimary,
        },
      });
    const parsedResponse =
      parseGqlResponse<CreateMobileChannel_createMobileChannel_MobileChannel>(
        "MobileChannel",
        createMobileChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "createMobileCommunicationChannel:parsedResponse",
      parsedResponse
    );

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !createMobileChannelResponse
    ) {
      throw new Error("Failed create mobile channel");
    }

    if (parsedResponse.data) {
      // assign the new mobile channel to the state if it was created successfully
      Object.assign(mobileState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END CREATE MOBILE CHANNEL
   */

  /**
   * VERIFY MOBILE CHANNEL
   */
  const verifyMobileChannelMutation = useCustomMutation<
    VerifyMobileChannel,
    VerifyMobileChannelVariables
  >(verifyMobileChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const verifyMobileChannel = async ({ mobileChannelId, verificationCode }) => {
    const verifyMobileChannelResponse =
      await verifyMobileChannelMutation.mutate({
        input: { mobileChannelId, verificationCode },
      });

    const parsedResponse =
      parseGqlResponse<VerifyMobileChannel_verifyMobileChannel_GenericSuccess>(
        "GenericSuccess",
        verifyMobileChannelResponse,
        apiErrorCodes.VERIFICATION_CODE_DOES_NOT_MATCH
      );

    console.log("verifyMobileChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !verifyMobileChannelResponse
    ) {
      throw new Error("Failed to verify mobile channel");
    }

    /**
     * Manually update the state of the mobile channel
     * because the response does not contain the updated mobile channel
     */
    if (parsedResponse.data?.success) {
      mobileState.isVerified = true;
    }

    return parsedResponse.data;
  };
  /**
   * END VERIFY MOBILE CHANNEL
   */

  /**
   * UPDATE MOBILE CHANNEL
   */
  const updateMobileChannelMutation = useCustomMutation<
    UpdateMobileChannel,
    UpdateMobileChannelVariables
  >(updateMobileChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleUpdateMobileChannel = async ({ isPrimary, value }) => {
    if (!mobileState.id) {
      return;
    }

    const updateMobileChannelResponse =
      await updateMobileChannelMutation.mutate({
        input: {
          mobileChannelId: mobileState.id,
          isPrimary,
          value,
        },
      });
    const parsedResponse =
      parseGqlResponse<UpdateMobileChannel_updateMobileChannel_MobileChannel>(
        "MobileChannel",
        updateMobileChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleUpdateMobileChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !updateMobileChannelResponse
    ) {
      throw new Error("Failed to update mobile channel");
    }

    /**
     * Use updated mobile state
     */
    if (parsedResponse.data) {
      // assign the new mobile channel to the state if it was created successfully
      Object.assign(mobileState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END UPDATE MOBILE CHANNEL
   */

  /**
   * DELETE MOBILE CHANNEL
   */
  const deleteMobileChannelMutation = useCustomMutation<
    DeleteMobileChannel,
    DeleteMobileChannelVariables
  >(deleteMobileChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleDeleteMobileChannel = async ({ mobileChannelId }) => {
    const deleteMobileChannelResponse =
      await deleteMobileChannelMutation.mutate({ input: { mobileChannelId } });
    const parsedResponse =
      parseGqlResponse<DeleteMobileChannel_deleteMobileChannel_GenericSuccess>(
        "GenericSuccess",
        deleteMobileChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleDeleteMobileChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !deleteMobileChannelResponse
    ) {
      throw new Error("Failed to delete mobile channel");
    }

    return parsedResponse.data;
  };
  /**
   * END DELETE MOBILE CHANNEL
   */

  /**
   * RESEND MOBILE VERIFICATION CODE
   */
  const {
    mutate: resendMobileCodeMutate,
    loading: resendMobileVerificationCodeLoading,
  } = useCustomMutation<
    ResendMobileChannelVerificationCode,
    ResendMobileChannelVerificationCodeVariables
  >(resendMobileChannelVerificationCodeGql);
  const handleResendMobileVerificationCode = async (mobileChannelId) => {
    const resendMobileCodeResponse = await resendMobileCodeMutate({
      input: {
        mobileChannelId,
      },
    });

    const parsedResponse =
      parseGqlResponse<ResendMobileChannelVerificationCode_resendMobileChannelVerificationCode_MobileChannel>(
        "MobileChannel",
        resendMobileCodeResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "handleResendMobileVerificationCode:parsedResponse",
      parsedResponse
    );

    if (!isEmpty(parsedResponse.error?.errors) || !resendMobileCodeResponse) {
      throw new Error("Failed to resend mobile verification code.");
    }

    /**
     * Use updated mobile state
     */
    if (parsedResponse.data?.id) {
      // assign the new mobile channel to the state
      Object.assign(mobileState, parsedResponse.data);
    }

    return parsedResponse.data;
  };

  const resendMobileVerificationCodeTimeAllowed = ref(
    getResendVerificationCodeDataMs(mobileState.verificationCodeLastSent, 0)
  );
  const resendMobileVerificationCodeTimeAllowedFinish = () => {
    resendMobileVerificationCodeTimeAllowed.value = 0;
  };

  watch(mobileState, (newMobileState) => {
    /**
     * Check if verificationCodeLastSent exists
     * 60 seconds will be added to this value so verification
     * code sending wont be available during the next 60 seconds
     */
    if (newMobileState.verificationCodeLastSent) {
      resendMobileVerificationCodeTimeAllowed.value =
        getResendVerificationCodeDataMs(
          newMobileState.verificationCodeLastSent
        );
    }
  });
  /**
   * END RESEND MOBILE VERIFICATION CODE
   */

  return {
    mobileState,
    createMobileCommunicationChannel,
    createMobileCommunicationChannelLoading: computed(
      () => createMobileChannelMutation.loading.value
    ),
    verifyMobileChannel,
    verifyMobileChannelLoading: computed(
      () => verifyMobileChannelMutation.loading.value
    ),
    handleUpdateMobileChannel,
    updateMobileChannelLoading: computed(
      () => updateMobileChannelMutation.loading.value
    ),
    handleDeleteMobileChannel,
    deleteMobileChannelLoading: computed(
      () => deleteMobileChannelMutation.loading.value
    ),
    resendMobileVerificationCodeTimeAllowed,
    handleResendMobileVerificationCode,
    resendMobileVerificationCodeLoading,
    resendMobileVerificationCodeTimeAllowedFinish,
  };
};
