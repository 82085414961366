
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useCommunicationMobileChannel } from "@/shared/composables/CommunicationChannels/useCommunicationMobileChannel";
import CustomAlert from "@/shared/components/CustomAlert.vue";
import CommonVerifyForm from "./CommonVerifyForm.vue";
import CommonCountdownResend from "@/web/views/CommunicationChannels/CommunicationChannelsForms/CommonCountdownResend.vue";
import attempt from "lodash/attempt";

export default defineComponent({
  components: {
    CustomAlert,
    CommonVerifyForm,
    CommonCountdownResend,
  },
  emits: ["on-change-mobile"],
  setup() {
    const { t } = useI18n();
    const verifyFormRef = ref();

    const {
      mobileState,
      verifyMobileChannel,
      verifyMobileChannelLoading,
      resendMobileVerificationCodeTimeAllowed,
      resendMobileVerificationCodeTimeAllowedFinish,
      handleResendMobileVerificationCode,
      resendMobileVerificationCodeLoading,
    } = useCommunicationMobileChannel({});

    const isVerificationError = ref(false);

    const localFormState = reactive({
      verificationCode: "",
    });

    const handleResendVerificationCode = async () => {
      isVerificationError.value = false; // reset when submitting
      handleResendMobileVerificationCode(mobileState.id);
    };

    const handleVerify = async (verificationCode) => {
      isVerificationError.value = false; // reset when submitting

      try {
        await verifyMobileChannel({
          mobileChannelId: mobileState.id,
          verificationCode,
        });
      } catch (error) {
        console.log(error);
        isVerificationError.value = true;

        attempt(verifyFormRef.value?.clearInput);
      }
    };

    return {
      t,
      verifyFormRef,
      isVerificationError,
      mobileState,
      localFormState,
      handleVerify,
      handleResendVerificationCode,
      resendMobileVerificationCodeTimeAllowed,
      resendMobileVerificationCodeTimeAllowedFinish,
      resendMobileVerificationCodeLoading,
      verifyMobileChannelLoading,
    };
  },
});
