
import CommonCardLayout from "@/shared/components/Layouts/CommonCardLayout.vue";
import { ChannelFormGroup } from "@/shared/utils/channels";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { getSocialIcon, isSocialPlatform } from "@/shared/utils/social";
import ChannelItem from "@/shared/components/Channels/ChannelItem.vue";
import ArrowIcon from "@/assets/icons/arrow.svg";
import AddChannelDropdown from "@/shared/components/Channels/AddChannelDropdown.vue";
import ChannelSocialItem from "@/shared/components/Channels/ChannelSocialItem.vue";

export default defineComponent({
  emits: [
    "on-add-channel",
    "on-edit-channel",
    "on-delete-channel",
    "on-verify-channel",
  ],
  props: {
    channelList: {
      type: Array as PropType<ChannelFormGroup[]>,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t, ArrowIcon };
  },
  methods: {
    getSocialIcon,
    isSocialPlatform,
  },
  components: {
    CommonCardLayout,
    ChannelItem,
    AddChannelDropdown,
    ChannelSocialItem,
  },
});
