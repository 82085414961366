import { i18nTranslate } from "@/plugins/i18n";

export type Channel = {
  key: string;
  title: string;
  value?: string;
};

export type ChannelFormGroup = {
  key: Channel["key"];
  value?: string;
  main?: boolean;
  verified?: boolean;
  showInContact?: boolean;
};

/**
 * Values here should reflect the values in the backend (/api/communicationChannels/__generated__/CommunicationChannels.ts)
 */
export const supportedChannels: Channel[] = [
  { key: "EmailChannel", title: i18nTranslate("Email") },
  { key: "MobileChannel", title: i18nTranslate("Mobile") },
  { key: "DomainChannel", title: i18nTranslate("Domain") },
  { key: "socials", title: i18nTranslate("Socials") },
];
