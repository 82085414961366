import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_statistic_countdown = _resolveComponent("a-statistic-countdown")!

  return (_openBlock(), _createBlock(_component_a_statistic_countdown, {
    value: _ctx.value,
    format: "(mm:ss)",
    onFinish: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('finish'))),
    class: "common-countdown-resend__resend-code-button-link-countdown ml-1"
  }, null, 8, ["value"]))
}