import gql from "graphql-tag";

export const verifyEmailChannelGql = gql`
  mutation VerifyEmailChannel($input: VerifyEmailChannelInput!) {
    verifyEmailChannel(input: $input) {
      ... on GenericSuccess {
        success
        message
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
