
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputOtp from "@/shared/components/Forms/InputOtp.vue";
import SubmitButton from "@/shared/components/Buttons/SubmitButton.vue";
import attempt from "lodash/attempt";

export default defineComponent({
  components: {
    FormGroup,
    InputOtp,
    SubmitButton,
  },
  props: {
    isVerified: Boolean,
    loading: Boolean,
    disabled: Boolean,
  },
  emits: ["on-verify"],
  setup(_props, { emit }) {
    const { t } = useI18n();
    const inputOtpRef = ref();

    const localFormState = reactive({
      verificationCode: "",
    });

    const handleVerify = () => {
      emit("on-verify", localFormState.verificationCode);
    };

    const clearInput = () => {
      attempt(inputOtpRef.value?.clearInput);
    };

    return {
      t,
      localFormState,
      handleVerify,
      inputOtpRef,
      clearInput,
    };
  },
});
