
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { supportedChannels } from "@/shared/utils/channels";
import { i18nTranslate } from "@/plugins/i18n";
import CustomMenu from "@/shared/components/CustomMenu.vue";

export default defineComponent({
  emits: ["on-add-channel"],
  props: {
    text: {
      default: i18nTranslate("+ Add channel"),
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      supportedChannels,
    };
  },
  components: { CustomMenu },
});
