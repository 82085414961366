
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import AddChannelDropdown from "@/shared/components/Channels/AddChannelDropdown.vue";
import EmptyList from "@/shared/components/Layouts/EmptyList.vue";

export default defineComponent({
  emits: ["on-add-channel"],
  props: {
    /**
     * Sometimes list seems to appear empty but just loading.
     * This prop is used to show/hide loading indicator.
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
      config,
    };
  },
  components: {
    AddChannelDropdown,
    EmptyList,
  },
});
