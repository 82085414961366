import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!

  return (_openBlock(), _createBlock(_component_InputText, {
    onGetInputRef: _ctx.setInputRef,
    label: _ctx.$attrs.label,
    placeholder: _ctx.$attrs.placeholder,
    rules: _ctx.$attrs.rules,
    name: _ctx.$attrs.name,
    value: _ctx.innerValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.innerValue) = $event))
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.$slots, (_, slot) => {
      return {
        name: slot,
        fn: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
        ])
      }
    })
  ]), 1032, ["onGetInputRef", "label", "placeholder", "rules", "name", "value"]))
}