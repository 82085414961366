import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_AddChannelDropdown = _resolveComponent("AddChannelDropdown")!
  const _component_ChannelSocialItem = _resolveComponent("ChannelSocialItem")!
  const _component_ChannelItem = _resolveComponent("ChannelItem")!
  const _component_a_collapse = _resolveComponent("a-collapse")!
  const _component_CommonCardLayout = _resolveComponent("CommonCardLayout")!

  return (_openBlock(), _createBlock(_component_CommonCardLayout, { class: "channel-list" }, {
    title: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, {
          level: 3,
          class: "m-0"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Channels list")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_AddChannelDropdown, {
          text: _ctx.t('Add one more'),
          onOnAddChannel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-add-channel', $event)))
        }, null, 8, ["text"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_collapse, {
        "expand-icon-position": "right",
        bordered: false,
        accordion: ""
      }, {
        expandIcon: _withCtx(() => [
          _createElementVNode("img", {
            src: _ctx.ArrowIcon,
            alt: "arrow"
          }, null, 8, _hoisted_2)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.channelList, (channel) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: channel.id
            }, [
              (_ctx.isSocialPlatform(channel.__typename))
                ? (_openBlock(), _createBlock(_component_ChannelSocialItem, {
                    key: channel.id,
                    class: "card-border-bottom",
                    channel: channel,
                    onOnRemove: ($event: any) => (_ctx.$emit('on-delete-channel', channel)),
                    onOnEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-edit-channel', $event)))
                  }, null, 8, ["channel", "onOnRemove"]))
                : (_openBlock(), _createBlock(_component_ChannelItem, {
                    key: channel.id,
                    channel: channel,
                    "show-edit": channel.isVerified,
                    "channel-unverified": !channel.isVerified,
                    class: "card-border-bottom",
                    onOnEdit: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('on-edit-channel', $event))),
                    onOnVerify: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('on-verify-channel', $event))),
                    onOnRemove: ($event: any) => (_ctx.$emit('on-delete-channel', channel))
                  }, null, 8, ["channel", "show-edit", "channel-unverified", "onOnRemove"]))
            ], 64))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}