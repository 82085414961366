import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_AddChannelDropdown = _resolveComponent("AddChannelDropdown")!
  const _component_EmptyList = _resolveComponent("EmptyList")!

  return (_ctx.loading)
    ? (_openBlock(), _createBlock(_component_a_skeleton, {
        key: 0,
        active: "",
        paragraph: { rows: 4 }
      }))
    : (_openBlock(), _createBlock(_component_EmptyList, {
        key: 1,
        title: _ctx.t('Communication channels list is empty'),
        body: 
      _ctx.t(
        'Channels allow to receive messages from services which use {merchantBrand}. It can be through email, mobile, socials. Your identity and data stays private with us.',
        {
          merchantBrand: _ctx.config.merchantBrand,
        }
      )
    
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_AddChannelDropdown, {
            class: "mt-4",
            onOnAddChannel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-add-channel', $event)))
          })
        ]),
        _: 1
      }, 8, ["title", "body"]))
}