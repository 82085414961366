import gql from "graphql-tag";

export const createDomainChannelGql = gql`
  mutation CreateDomainChannel($input: CreateDomainChannelInput!) {
    createDomainChannel(input: $input) {
      ... on DomainChannel {
        id
        created
        value
        isVerified
        isPrimary
        isPublic
        verified
        verificationLastAttempt
        verificationAttemptCount
        updated
        verificationCode
        verificationFile
        callbackUrl
        key
        created
        updated
        domainVerificationFileUrl
        verificationMethod
      }
      ... on ResponseErrors {
        errors {
          code
          message
          displayMessage
        }
      }
    }
  }
`;
