import { computed, reactive } from "vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { createDomainChannelGql } from "@/api/communicationChannels/createDomainChannel";
import {
  CreateDomainChannel,
  CreateDomainChannelVariables,
  CreateDomainChannel_createDomainChannel_DomainChannel,
} from "@/api/communicationChannels/__generated__/CreateDomainChannel";
import { apiErrorCodes } from "@/shared/utils/constants";
import isEmpty from "lodash/isEmpty";
import { updateDomainChannelGql } from "@/api/communicationChannels/updateDomainChannel";
import {
  UpdateDomainChannel,
  UpdateDomainChannelVariables,
  UpdateDomainChannel_updateDomainChannel_DomainChannel,
} from "@/api/communicationChannels/__generated__/UpdateDomainChannel";
import {
  DeleteDomainChannel,
  DeleteDomainChannelVariables,
  DeleteDomainChannel_deleteDomainChannel_GenericSuccess,
} from "@/api/communicationChannels/__generated__/DeleteDomainChannel";
import { deleteDomainChannelGql } from "@/api/communicationChannels/deleteDomainChannel";
import merge from "lodash/merge";
import { communicationChannelsGql } from "@/api/communicationChannels/communicationChannelList";
import { VerifyDomainChannelInput } from "../../../../__generated__/globalTypes";
import {
  VerifyDomainChannel,
  VerifyDomainChannelVariables,
  VerifyDomainChannel_verifyDomainChannel_DomainChannel,
} from "@/api/communicationChannels/__generated__/VerifyDomainChannel";
import { verifyDomainChannelGql } from "@/api/communicationChannels/verifyDomainChannel";

/**
 * __typename is required for the on-delete emit to work
 */
const emptyDomainState = {
  __typename: "DomainChannel",
  id: null,
  value: "",
  isVerified: false,
  isPrimary: false,
  isPublic: false,
  verificationCodeLastSent: null,
  verificationLastAttempt: null,
};

/**
 * Old Value will be used to check when user try to change domain
 */
interface DomainChannelState
  extends CreateDomainChannel_createDomainChannel_DomainChannel {
  oldValue: CreateDomainChannel_createDomainChannel_DomainChannel["value"];
}

export const useCommunicationDomainChannel = (initialFormState) => {
  /**
   * Merge initial form state with empty domain state
   * Initial form state has values if domain channel is being edited
   */
  const domainState = reactive<Partial<DomainChannelState>>(
    merge(emptyDomainState, initialFormState)
  );

  /**
   * CREATE DOMAIN CHANNEL
   */
  const createDomainChannelMutation = useCustomMutation<
    CreateDomainChannel,
    CreateDomainChannelVariables
  >(createDomainChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const createDomainCommunicationChannel = async ({ value, isPrimary }) => {
    const createDomainChannelResponse =
      await createDomainChannelMutation.mutate({
        input: {
          value,
          isPrimary,
        },
      });
    const parsedResponse =
      parseGqlResponse<CreateDomainChannel_createDomainChannel_DomainChannel>(
        "DomainChannel",
        createDomainChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log(
      "createDomainCommunicationChannel:parsedResponse",
      parsedResponse
    );

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !createDomainChannelResponse
    ) {
      throw new Error("Failed create domain channel");
    }

    if (parsedResponse.data) {
      // assign the new domain channel to the state if it was created successfully
      Object.assign(domainState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END CREATE Domain CHANNEL
   */

  /**
   * UPDATE DOMAIN CHANNEL
   */
  const updateDomainChannelMutation = useCustomMutation<
    UpdateDomainChannel,
    UpdateDomainChannelVariables
  >(updateDomainChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleUpdateDomainChannel = async ({
    isPrimary,
    value,
    callbackUrl,
  }: {
    isPrimary: boolean;
    value: string;
    callbackUrl?: string;
  }) => {
    if (!domainState.id) {
      return;
    }

    const updateDomainChannelResponse =
      await updateDomainChannelMutation.mutate({
        input: {
          domainChannelId: domainState.id,
          isPrimary,
          value,
          ...(callbackUrl ? { callbackUrl } : {}),
        },
      });
    const parsedResponse =
      parseGqlResponse<UpdateDomainChannel_updateDomainChannel_DomainChannel>(
        "DomainChannel",
        updateDomainChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleUpdateDomainChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !updateDomainChannelResponse
    ) {
      throw new Error("Failed to update domain channel");
    }

    /**
     * Use updated domain state
     */
    if (parsedResponse.data) {
      // assign the new domain channel to the state if it was created successfully
      Object.assign(domainState, parsedResponse.data);
    }

    return parsedResponse.data;
  };
  /**
   * END UPDATE DOMAIN CHANNEL
   */

  /**
   * DELETE DOMAIN CHANNEL
   */
  const deleteDomainChannelMutation = useCustomMutation<
    DeleteDomainChannel,
    DeleteDomainChannelVariables
  >(deleteDomainChannelGql, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: communicationChannelsGql }],
  });
  const handleDeleteDomainChannel = async ({ domainChannelId }) => {
    const deleteDomainChannelResponse =
      await deleteDomainChannelMutation.mutate({ input: { domainChannelId } });
    const parsedResponse =
      parseGqlResponse<DeleteDomainChannel_deleteDomainChannel_GenericSuccess>(
        "GenericSuccess",
        deleteDomainChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("handleDeleteDomainChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !deleteDomainChannelResponse
    ) {
      throw new Error("Failed to delete domain channel");
    }

    return parsedResponse.data;
  };
  /**
   * END DELETE DOMAIN CHANNEL
   */

  /**
   * VERIFY DOMAIN CHANNEL
   */
  const {
    loading: verifyDomainChannelLoading,
    mutate: verifyDomainChannelMutate,
  } = useCustomMutation<VerifyDomainChannel, VerifyDomainChannelVariables>(
    verifyDomainChannelGql
  );
  const verifyDomainChannel = async (input: VerifyDomainChannelInput) => {
    const verifyDomainChannelResponse = await verifyDomainChannelMutate({
      input,
    });
    const parsedResponse =
      parseGqlResponse<VerifyDomainChannel_verifyDomainChannel_DomainChannel>(
        "DomainChannel",
        verifyDomainChannelResponse,
        apiErrorCodes.INTERNAL_ERROR
      );

    console.log("verifyDomainChannel:parsedResponse", parsedResponse);

    if (
      !isEmpty(parsedResponse.error?.errors) ||
      !verifyDomainChannelResponse
    ) {
      throw new Error("Failed to delete domain channel");
    }

    if (parsedResponse.data?.verified) {
      domainState.isVerified = true;
    }

    return parsedResponse.data;
  };
  /**
   * END VERIFY DOMAIN CHANNEL
   */

  return {
    domainState,
    createDomainCommunicationChannel,
    createDomainCommunicationChannelLoading: computed(
      () => createDomainChannelMutation.loading.value
    ),
    handleUpdateDomainChannel,
    updateDomainChannelLoading: computed(
      () => updateDomainChannelMutation.loading.value
    ),
    handleDeleteDomainChannel,
    deleteDomainChannelLoading: computed(
      () => deleteDomainChannelMutation.loading.value
    ),
    verifyDomainChannel,
    verifyDomainChannelLoading,
  };
};
